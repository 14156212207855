import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchToPrepareNewLc } from '../../reduxStore/LcPreparation/LcPreparationSlice';
import QRScanner from '../../assets/QRScanner';

const CreateNewLc = () => {
  const dispatch = useDispatch();
  const [lcPreparationData, setLcPreparationData] = useState({
    lcId: "",
    lcQty: "",
    scId: "",
    scQty: ""
  });

  const handleInputDataOfLcPreparation = (key, value) => {
    setLcPreparationData({ ...lcPreparationData, [key]: value });
  };

  const submitFilledLcPreparationData = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(fetchToPrepareNewLc(lcPreparationData)).unwrap();
      console.log("response", response);
      if (response.success) {

        setLcPreparationData({
          lcId: "",
          lcQty: "",
          scId: lcPreparationData.scId,
          scQty: lcPreparationData.scQty
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearAll = async () => {
    setLcPreparationData({
      lcId: "",
      lcQty: "",
      scId: "",
      scQty: ""
    });
  };

  const lcIDResult = (result) => {
    setLcPreparationData({ ...lcPreparationData, lcId: result });
  };
  const lcQtyResult = (result) => {
    setLcPreparationData({ ...lcPreparationData, lcQty: result });
  };

  const scIDResult = (result) => {
    setLcPreparationData({ ...lcPreparationData, scId: result });
  };
  const scQtyResult = (result) => {
    setLcPreparationData({ ...lcPreparationData, scQty: result });
  };

  return (
    <>
      <form onSubmit={submitFilledLcPreparationData} className="max-w-sm bg-teal-50 p-4 sm:mx-auto mx-4 my-4 border border-teal-200 rounded-lg shadow-lg justify-center">
        <div className="p-1">
          <label htmlFor="lcId" className="block mt-2  text-sm font-medium text-teal-900">
            {/* Scan LC ID */}
            <QRScanner idName={"lcIdScanner"} buttonName={"Scan LC ID"} resultOnScanSuccess={lcIDResult} />
          </label>
          <input
            type="text" id="lcId" value={lcPreparationData.lcId} name="lcId"
            onChange={(e) => handleInputDataOfLcPreparation(e.target.name, e.target.value)}
            className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Input LC ID"
            required
          />
        </div>

        <div className="p-1">
          <label htmlFor="lcQty" className="block mt-2 text-sm font-medium text-teal-900">
            {/* Fill LC Quantity (in ml.) */}
            <QRScanner idName={"lcQtyScanner"} buttonName={"Scan LC Qty."} resultOnScanSuccess={lcQtyResult} />
          </label>
          <input
            type="number" id="lcQty" value={lcPreparationData.lcQty} name="lcQty"
            onChange={(e) => handleInputDataOfLcPreparation(e.target.name, e.target.value)}
            className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Fill LC Quantity"
            required
            min={0}
          />
        </div>


        <div className="p-1">
          <label htmlFor="scId" className="block mt-2 text-sm font-medium text-teal-900">
            {/* Scan SC ID */}
            <QRScanner idName={"scIdScanner"} buttonName={"Scan SC ID"} resultOnScanSuccess={scIDResult} />
          </label>
          <input
            type="text" id="scId" value={lcPreparationData.scId} name="scId"
            onChange={(e) => handleInputDataOfLcPreparation(e.target.name, e.target.value)}
            className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Scan SC ID"
            required
          />
        </div>
        
        <div className="p-1">
          <label htmlFor="scQty" className="block mt-2 text-sm font-medium text-teal-900">
            <QRScanner idName={"scQtyScanner"} buttonName={"Scan SC Qty."} resultOnScanSuccess={scQtyResult} />
            {/* Input SC Quantity (in ml.) */}
          </label>
          <input
            type="number" id="scQty" value={lcPreparationData.scQty} name="scQty"
            onChange={(e) => handleInputDataOfLcPreparation(e.target.name, e.target.value)}
            className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Input SC Quantity"
            required
            min={0}
          />
        </div>



        <div className='py-4'>
          <button
            type="submit"
            className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Submit
          </button>

          <button
            type="button"
            onClick={() => clearAll()}
            className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Clear All
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateNewLc;

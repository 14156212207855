import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { rdxStore } from './reduxStore/rdxStore';
import { Provider } from "react-redux"
import { ToastContainer } from 'react-toastify';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={rdxStore}>

      {/* <React.StrictMode> */}
        <App />
        <ToastContainer autoClose={4000} hideProgressBar={true} theme="colored"/>
      {/* </React.StrictMode> */}
  </Provider>
);


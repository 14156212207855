import React from 'react'
import HumidifiersData from '../../GrowthChamber/HumidifiersData'
import HumidifierTopBar from './HumidifierTopBar'

const AdminHumidifiersData = () => {
  return (
    <>
    <HumidifierTopBar />
      <HumidifiersData/>
    </>
  )
}

export default AdminHumidifiersData

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../assets/Loader';
import { fetchAddedMouldsData } from '../../reduxStore/GrowthChamber/HumidiferDataSlice';
import {  fetchHumidifiers } from '../../reduxStore/GrowthChamber/HumidifierSlice';
import Pagination from '../../assets/Pagination';
import convertToIST from '../../assets/FormatTime';
import {useNavigate } from 'react-router-dom';



const HumidifiersData = () => {
    const state = useSelector((state) => state.humidifierDataReducer);
    const state2 = useSelector((state) => state.humidifierReducer)

    const dispatch = useDispatch();
    const [humidifierData, setHumidifierData] = useState([]);
    const [allHumidifiers, setallHumidifiers] = useState();
    const navigate = useNavigate();
    // const [data, setData] = useState([])
    const [totalCount, setTotalCount] = useState()
    const [resultPerPage, setResultPerPage] = useState(5);


    useEffect(() => {
        dispatch(fetchAddedMouldsData(10, 1));
        dispatch(fetchHumidifiers())
    }, []);

    const [selectedHumidifier, setSelectedHumidifier] = useState('');


    const handleChange = (event) => {
        setSelectedHumidifier(event.target.value);
        if (event.target.value === '') {
            setHumidifierData(state.data.humidifiersData)
        }
        else {
            setHumidifierData(state.data.humidifiersData.filter(h => h.humidifierNo === event.target.value))
        }
    };

    useEffect(() => {
        if (state && state.data && state.data.humidifiersData) {
            setHumidifierData(state.data.humidifiersData);
            setTotalCount(state.data.totalCount)
            //   setResultPerPage(state.data.resultPerPage)
        }
    }, [state]);
    useEffect(() => {
        if (state2 && state2.data && state2.data.humidifiers) {
            setallHumidifiers(state2.data.humidifiers);
        }
    }, [state2]);

    // console.log("humidifierData",humidifierData);

    return (
        <>
            <div className="flex flex-col">
                <div className="overflow-x-auto">
                    <div className='text-center text-3xl font-bold m-4'>Growth Chamber Data</div>
                    <div className='flex flex-row justify-end mr-8'>
                        <div className='flex flex-col m-2 p-2 '>
                            <div className='text-xl font-bold m-2'>Select Humidifier</div>
                            <div>
                                <select className="border border-teal-300 rounded-md px-4 py-2 w-full" value={selectedHumidifier} onChange={handleChange}>
                                    <option value="">All</option>
                                    {
                                        allHumidifiers && allHumidifiers.map((h, ind) => {
                                            return <option value={h.serialNo}>{h.serialNo}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="p-1.5 min-w-full inline-block ">
                        <div className="border rounded-lg shadow overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">S.No</th>
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Batch IDs</th>
                                        {/* <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Moulds</th> */}
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Humidifier No</th>
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Placing time</th>
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Placed By</th>
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Growth Type</th>
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border"></th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">

                                    {state.isLoading &&
                                        <tr>
                                            <td colSpan={'8'} className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-center">
                                                <Loader />
                                            </td>
                                        </tr>
                                    }

                                    {humidifierData.map((currData, ind) => (
                                        <tr className='hover:bg-teal-300' key={currData._id}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{ind + 1}</td>
                                            <td className="px-6 py-4  text-sm text-gray-800 border">{currData.batchId}</td>
                                            {/* <td className="px-6 py-4  text-sm text-gray-800 border">{currData.moldIds.join(', ')}</td> */}
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.humidifierNo}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{convertToIST(currData.placingTime)}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.placedBy}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.growthType}</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border"><button type="button" onClick={() => {
                                                navigate('/growthData', { state: { humidifier: currData } })
                                            }} className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center me-2 mb-2 " >More</button></td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {
                 <Pagination getData={fetchAddedMouldsData} totalData={totalCount} resultPerPage={resultPerPage} />
            }

        </>
    );
};

export default HumidifiersData;















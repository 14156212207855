import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const fetchToRejectNewDeMould = createAsyncThunk(
    'rejectDeMould/fetchToRejectNewDeMould',
    async (deMouldData, { rejectWithValue }) => {
        try {
            const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
            const url = `${BACK_HOST}api/v1/rejectNewDeMould`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(deMouldData),
                credentials: 'include'
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.message);
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            toast.success(data.message);
            return data;

        } catch (error) {
            console.error("Failed to reject new de-mould: ", error);
            return rejectWithValue(error.message);
        }
    }
);

export const fetchToGetRejectedDeMouldData = createAsyncThunk(
    'rejectDeMould/fetchToGetRejectedDeMouldData',
    async (queryParams, { rejectWithValue }) => {
        try {
            const params = new URLSearchParams(queryParams);
            const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
            const url = `${BACK_HOST}api/v1/getRejectedDeMoldData?${params}`;
            console.log("data");
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error("Failed to fetch rejected de-mould data.");
            }

            const data = await response.json();
            console.log("data",data);
            return data;

        } catch (error) {
            console.error("Failed to fetch rejected de-mould data: ", error);
            return rejectWithValue(error.message);
        }
    }
);

export const fetchToDeleteRejectedDeMouldData = createAsyncThunk(
    'rejectDeMould/fetchToDeleteRejectedDeMouldData',
    async (id, { rejectWithValue }) => {
        try {
            const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
            const url = `${BACK_HOST}api/v1/admin/deleteRejectedDeMouldData/${id}`;
            const response = await fetch(url, {
                method: 'DELETE',
                credentials: 'include'
            });

            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.message);
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            toast.success(data.message);
            return data;

        } catch (error) {
            console.error("Failed to delete rejected de-mould data: ", error);
            return rejectWithValue(error.message);
        }
    }
);

const rejectDeMouldSlice = createSlice({
    name: "rejectDeMould",
    initialState: {
        data: null,
        isLoading: false,
        isError: false,
        errorMessage: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        // Reject new de-mould
        builder
            .addCase(fetchToRejectNewDeMould.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(fetchToRejectNewDeMould.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchToRejectNewDeMould.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload;
            });

        // Get rejected de-mould data
        builder
            .addCase(fetchToGetRejectedDeMouldData.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(fetchToGetRejectedDeMouldData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchToGetRejectedDeMouldData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload;
            });

        // Delete rejected de-mould data
        builder
            .addCase(fetchToDeleteRejectedDeMouldData.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(fetchToDeleteRejectedDeMouldData.fulfilled, (state, action) => {
                state.isLoading = false;
                // state.data = state.data.filter(item => item._id !== action.payload._id);
            })
            .addCase(fetchToDeleteRejectedDeMouldData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.errorMessage = action.payload;
            });
    }
});

export default rejectDeMouldSlice.reducer;

import React from 'react'
import Navbar from '../../assets/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProfileCard from '../../assets/Profile'
import RejectNewMould from './RejectNewMould'
import DataOfMouldIncubation from './DataOfMouldIncubation'
import Login from '../../assets/Login'
import Logout from '../../assets/Logout'
import Footer from '../Footer'

const NavigateInMouldIncubation = () => {
  return (
    <>
      <BrowserRouter>
        <Navbar moduleName={"Mould Incubation"} home={"/"} viewData={"/viewDataOfMouldIncubation"} />
        <Routes>
          <Route path="/" element={<RejectNewMould />} />
          <Route path="/viewDataOfMouldIncubation" element={< DataOfMouldIncubation />} />
          <Route path="/myProfile" element={< ProfileCard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
   
      </BrowserRouter>
    </>
  )
}

export default NavigateInMouldIncubation

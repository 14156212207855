import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../assets/Loader';
import { fetchToGetRejectedMoldData } from '../../reduxStore/MouldIncubation/MouldIncubationSlice';
import FormatTime from '../../assets/FormatTime';
import Pagination from '../../assets/Pagination';

const AdminDataOfMouldIncubation = () => {
    const state = useSelector((state) => state.mouldIncubationReducer);
    const dispatch = useDispatch();
    const [getAllDataFromCollection, setGetAllDataFromCollection] = useState([]);

    useEffect(() => {
        dispatch(fetchToGetRejectedMoldData());
    }, [dispatch]);

    useEffect(() => {
        if (state && state.data && state.data.mouldRejectedData) {
            setGetAllDataFromCollection(state.data.mouldRejectedData);
        }
    }, [state]);

 

    const [totalNumberOfData, setTotalNumberOfData] = useState(0); // Ensure initialized to 0
    const [resultPerPage, setResultPerPage] = useState(0); // Ensure initialized to 0

    useEffect(() => {
        if (state && state.data && state.data.mouldRejectedData) {
          setTotalNumberOfData(state.data.totalRejectedMould)
          setResultPerPage(state.data.resultPerPage)
        }
      }, [state]);

    return (
        <>
        <div className="flex flex-col">
            <div className="overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block">
                    <div className="border rounded-lg shadow overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Mould ID</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Reason Of Rejection</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Type Of Mould</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Rejection Time</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Mould Filling Time</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Mould Rejected By</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {state.isLoading &&
                                    <tr>
                                        <td colSpan={'6'} className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                }

                                {(Array.isArray(getAllDataFromCollection) ? getAllDataFromCollection : []).map((currData) => (
                                    <tr className='hover:bg-teal-300' key={currData._id}>
                                 
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.mouldId}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.reasonOfRejection}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.previousDetailsTillMouldFilling.typeOfMould}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData.rejectionTime)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData.previousDetailsTillMouldFilling.fillingDate)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.mouldRejectedBy}</td>
                                       
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Pagination getData={fetchToGetRejectedMoldData} totalData={totalNumberOfData} resultPerPage={resultPerPage}/>
        </>
    );
};

export default AdminDataOfMouldIncubation;

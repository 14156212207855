import { useDispatch, useSelector } from "react-redux";
import { fetchBagIncubationData, fetchToDeleteBagIncubationData, fetchToUpdateBagIncubationData } from '../../../reduxStore/Incubation/IncubationSlice';
import { useEffect, useState } from 'react';
import Loader from '../../../assets/Loader';
import FormatTime from '../../../assets/FormatTime';

function AdminDataOfBagStoreRoom() {
  const state = useSelector((state) => state.bagIncubationReducer);

  const dispatch = useDispatch();
  const [updatingId, setUpdatingId] = useState(null);
  const [disabledButtons, setDisabledButtons] = useState(false);
  const [fillingDataForUpdateTheCurrValue, setFillingDataForUpdateTheCurrValue] = useState({});
  const [getAllDataFromCollection, setGetAllDataFromCollection] = useState([]);

  useEffect(() => {
    dispatch(fetchBagIncubationData());   
  }, [dispatch]);

  useEffect(() => {
    if (state && state.data && state.data.dataOfRejectedBag) {
      setGetAllDataFromCollection(state.data.dataOfRejectedBag);
    }
  }, [state]);

  const deleteData = (id) => {
    if (window.confirm("Do you really want to delete?")) {
      
      dispatch(fetchToDeleteBagIncubationData(id)).then(() => {
        dispatch(fetchBagIncubationData());
      });
    }
  }

  const updateData = (id) => {
    setUpdatingId(id);
    setDisabledButtons(true);
    const updatingData = getAllDataFromCollection.find((currItem) => currItem._id === id);
    if (updatingData) {
        setFillingDataForUpdateTheCurrValue(updatingData);
    } else {
        console.error("No data found for the provided id");
    }
}

const handleInputData = (key,value) => {
  setFillingDataForUpdateTheCurrValue({...fillingDataForUpdateTheCurrValue , [key]:value});
}

 

  const save = (id) => {
    const { bagRejectionTime, __v, _id, previousStatusTillBagInoculation , ...onlyRequiredValue  } = fillingDataForUpdateTheCurrValue;


    dispatch(fetchToUpdateBagIncubationData({ id, filledData: onlyRequiredValue })).then(() => {
      setUpdatingId(null);
      setDisabledButtons(false);
      setFillingDataForUpdateTheCurrValue({});
      dispatch(fetchBagIncubationData());
    });
  }

  const updateCancel = () => {
    setUpdatingId(null);
    setDisabledButtons(false);
    setFillingDataForUpdateTheCurrValue({});
  }




  return (
    <>

 


      <div className="flex flex-col">
        <div className=" overflow-x-auto">
          <div className="p-1.5 min-w-full inline-block ">
            <div className="border rounded-lg shadow overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 ">
                  <tr>
                    <th className="px-6 py-6  text-sm font-medium text-gray-500 uppercase border">Bag Id</th>
                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">REASON</th>
                    <th className="px-6 py-6  text-sm font-medium text-gray-500 uppercase border">Bag Rejected By</th>
                    <th className="px-6 py-6  text-sm font-medium text-gray-500 uppercase border">REJECTION DATE</th>
                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Bag Qty.(in gm)</th>
                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Lc/Jam Id</th>
                    <th className="px-6 py-6  text-sm font-medium text-gray-500 uppercase border">Lc/Jam Qty.(ml)</th>
                    <th className="px-6 py-6  text-sm font-medium text-gray-500 uppercase border">Bag Placing Date(Bag incubation)</th>
                    <th className="px-6 py-6  text-sm font-medium text-gray-500 uppercase border">Remove</th>
                    <th className="px-6 py-6  text-sm font-medium text-gray-500 uppercase border">Update</th>
                        
                  </tr>

                </thead>

                <tbody className="divide-y divide-gray-200">
                    {state.isLoading &&
                      <tr>
                        <td colSpan={'8'} className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-center">
                          <Loader />
                        </td>
                      </tr>
                    }

                    {getAllDataFromCollection && getAllDataFromCollection.map((currItem) => (
                      <tr className='hover:bg-teal-300' key={currItem._id}>
                        {updatingId && currItem._id === updatingId ? (
                          <>

                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                              <input type="String" id="bagId" value={fillingDataForUpdateTheCurrValue.bagId} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="bagId" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Bag Id" required />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                              <input type="String" id="reasonOfRejection" value={fillingDataForUpdateTheCurrValue.reasonOfRejection} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="reasonOfRejection" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Reason" required />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                              <input type="String" id="bagRejectedBy" value={fillingDataForUpdateTheCurrValue.bagRejectedBy} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="bagRejectedBy" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Time Stamp(No Need To Filled)"  />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                              <input type="String" id="bagRejectionTime" value={fillingDataForUpdateTheCurrValue.bagRejectionTime} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="acceptanceOrRejectionTimeStamp" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="Time Stamp(No Need To Filled)" disabled={disabledButtons} />
                            </td>
                           

                           
                            {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{e.incubation.acceptanceOrRejectionTimeStamp}</td> */}
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currItem.previousStatusTillBagInoculation?.bagQty}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currItem.previousStatusTillBagInoculation?.JamOrLcId}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currItem.previousStatusTillBagInoculation?.JamOrLcQty}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currItem.previousStatusTillBagInoculation?.inoculationTimeStamp}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                              
                            <button type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none"  disabled={disabledButtons}>
                                Remove
                              </button>
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                              <div className="flex justify-between">
                                <button type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-green-500 hover:text-green-800 disabled:opacity-50 disabled:pointer-events-none mr-2" onClick={() => { save(currItem._id) }}>Save</button>
                                <button type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-yellow-600 hover:text-yellow-800 disabled:opacity-50 disabled:pointer-events-none" onClick={updateCancel}>Cancel</button>
                              </div>
                            </td>
                          </>
                        ) : (
                          <>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currItem.bagId}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currItem.reasonOfRejection}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currItem.bagRejectedBy}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currItem.bagRejectionTime)}</td>

                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currItem.previousStatusTillBagInoculation?.bagQty}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currItem.previousStatusTillBagInoculation?.JamOrLcId}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currItem.previousStatusTillBagInoculation?.JamOrLcQty}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currItem.previousStatusTillBagInoculation?.inoculationTimeStamp)}</td>


                            <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                              <button type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none" onClick={() => { deleteData(currItem._id) }} disabled={disabledButtons}>
                                Remove
                              </button>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                              <button type="button" className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-green-600 hover:text-green-800 disabled:opacity-50 disabled:pointer-events-none" onClick={() => { updateData(currItem._id) }} disabled={disabledButtons}>Edit</button>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>

              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminDataOfBagStoreRoom;

import React, { useState } from 'react';
import ScanMultiIdsOvenOut from './ScanMultiIdsOvenOut';
import Modal from '../../assets/OvenModal';
import { useDispatch } from 'react-redux';
import { fetchToAddNewOvenOutBatch, fetchToCheckOvenOutIdExist } from '../../reduxStore/OvenOut/OvenOutSlice';

const OvenOutNewFGBatch = () => {
  const [isOpen, setIsOpen] = useState(false); // State to manage modal open/close
  const [submitCreatedDataOfOvenIn, setSubmitCreatedDataOfOvenIn] = useState([]); // State to store submitted data
  const dispatch = useDispatch();

  async function submittedDataIds(submittedData) {
    const response = await dispatch(fetchToAddNewOvenOutBatch(submittedData)).unwrap(); // Dispatch action to add new batch
    const createdData = response?.createdData?.map((currItem) => currItem.ovenInSemiFGIds); // Map response to get IDs
    setSubmitCreatedDataOfOvenIn(createdData); // Update state with created data
    setIsOpen(true); // Open modal
  }

  const closeModal = () => setIsOpen(false); // Function to close modal

  const checkIsIdExist = async (id) => {
    try {
      const response = await dispatch(fetchToCheckOvenOutIdExist(id)).unwrap();
      return response;
    } catch (error) {
      // return await dispatch(fetchToCheckOvenOutIdExist(id)).unwrap()
      return { success: false, message: error.message }; // Handle error

    }
  };

  return (
    <>
      <ScanMultiIdsOvenOut
        scannerIdName="ovenOutScanner"
        labelName="FG ID:"
        submitOvenOutData={submittedDataIds} // Pass submitted data function to child component
        checkIsIdExist={checkIsIdExist} // Pass check ID existence function to child component
      />
      <Modal isOpen={isOpen} closeModal={closeModal} submitCreatedData={submitCreatedDataOfOvenIn} />
    </>
  );
}

export default OvenOutNewFGBatch;

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../assets/Loader';
import FormatTime from '../../assets/FormatTime';
import { fetchAllOvenInData } from '../../reduxStore/OvenIn/OvenInSlice';
import Pagination from '../../assets/Pagination';

const DataOfOvenIn = () => {
  const state = useSelector((state) => state.ovenInReducer);
  const dispatch = useDispatch();
  const [getAllDataFromCollection, setGetAllDataFromCollection] = useState([]);


  useEffect(() => {
    dispatch(fetchAllOvenInData());
  }, [dispatch]);

  useEffect(() => {
    if (state && state.data && state.data.ovenInData) {
      setGetAllDataFromCollection(state.data.ovenInData);
    }
  }, [state]);


  const [totalNumberOfData, setTotalNumberOfData] = useState(0); // Ensure initialized to 0
    const [resultPerPage, setResultPerPage] = useState(0); // Ensure initialized to 0

    useEffect(() => {
        if (state && state.data && state.data.ovenInData) {
          setTotalNumberOfData(state.data.totalOvenInSemiFG)
          setResultPerPage(state.data.resultPerPage)
        }
      }, [state]);


  return (
    <>
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block">
          <div className="border rounded-lg shadow overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Oven In Semi FG IDs</th>
                  <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Batch ID of Oven In</th>
                  <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Oven In Time</th>
                  <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Oven In By</th>
                  <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Humidifier In Time</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {state.isLoading &&
                  <tr>
                    <td colSpan={'6'} className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-center">
                      <Loader />
                    </td>
                  </tr>
                }

                {(Array.isArray(getAllDataFromCollection) ? getAllDataFromCollection : []).map((currData) => (
                  <tr className='hover:bg-teal-300' key={currData._id}>


                    <>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData?.ovenInSemiFGIds}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData?.batchIdOfOvenIn}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData?.ovenInTime)}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData?.ovenInBy}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData?.previousDetailsTillHumidifier?.jamPreparationTime)}</td>

                    </>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <Pagination getData={fetchAllOvenInData} totalData={totalNumberOfData} resultPerPage={resultPerPage}/>
    </>
  );
};



export default DataOfOvenIn








import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import { fetchToPrepareNewJam } from '../../reduxStore/JamPreparation/JamPreparationSlice';
import QRScanner from '../../assets/QRScanner';


const CreateNewJam = () => {

  const dispatch = useDispatch();
  const [jamPreparationData, setJamPreparationData] = useState({
    jamId: "",
    jamQty: "",
    lcId: "",
    lcQty: ""
  });

  const handleInputDataOfJamPreparation = (key, value) => {
    setJamPreparationData({ ...jamPreparationData, [key]: value });
  };


  const submitFilledJamPreparationData = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(fetchToPrepareNewJam(jamPreparationData)).unwrap();
      if (response.success) {
        setJamPreparationData({
          jamId: "",
          jamQty: "",
          lcId: jamPreparationData.lcId,
          lcQty: jamPreparationData.lcQty
        })
      }

    } catch (error) {
      console.log(error);
    }
  };

  const clearAll = async () => {
    setJamPreparationData({
      jamId: "",
      jamQty: "",
      lcId: "",
      lcQty: ""
    })
  }


  const jamIDResult = (result) => {
    setJamPreparationData({ ...jamPreparationData, jamId: result });
  };

  const jamQtyResult = (result) => {
    setJamPreparationData({ ...jamPreparationData, jamQty: result });
  };
  const lcIDResult = (result) => {
    setJamPreparationData({ ...jamPreparationData, lcId: result });
  };
  const lcQtyResult = (result) => {
    setJamPreparationData({ ...jamPreparationData, lcQty: result });
  };



  return (
    <>


      <form onSubmit={submitFilledJamPreparationData} className="max-w-sm bg-teal-50 p-4 sm:mx-auto mx-4 my-4 border border-teal-200 rounded-lg shadow-lg justify-center">

        <div className='p-1'>
          <label htmlFor="jamId" className="block mt-2  text-sm font-medium text-teal-900">
            {/* Scan Jam ID */}
            <QRScanner idName={"jamIdScanner"} buttonName={"Scan Jam ID"} resultOnScanSuccess={jamIDResult} />
          </label>


          <input
            type="text" id="jamId" value={jamPreparationData.jamId} name="jamId"
            onChange={(e) => handleInputDataOfJamPreparation(e.target.name, e.target.value)}
            className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Input Jam ID"
            required
          />
        </div>

        <div className="p-1">
          <label htmlFor="jamQty" className="block mt-2 text-sm font-medium text-teal-900">
            {/* Jam Qty. (in ml.) */}
            <QRScanner idName={"jamQtyScanner"} buttonName={"Scan Jam Qty."} resultOnScanSuccess={jamQtyResult} />
          </label>
          <input
            type="number" id="jamQty" value={jamPreparationData.jamQty} name="jamQty"
            onChange={(e) => handleInputDataOfJamPreparation(e.target.name, e.target.value)}
            className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Input Jam Quantity"
            required
            min={0}
          />
        </div>

        <div className="p-1">
          <label htmlFor="lcId" className="block mt-2 text-sm font-medium text-teal-900">
            {/* Scan LC ID */}
            <QRScanner idName={"lcIdScanner"} buttonName={"Scan LC ID"} resultOnScanSuccess={lcIDResult} />
          </label>
          <input
            type="text" id="lcId" value={jamPreparationData.lcId} name="lcId"
            onChange={(e) => handleInputDataOfJamPreparation(e.target.name, e.target.value)}
            className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Input LC ID"
            required
          />
        </div>

        <div className="p-1">
          <label htmlFor="lcQty" className="block mt-2 text-sm font-medium text-teal-900">
            {/* LC Qty. (in ml.) */}
            <QRScanner idName={"lcQtyScanner"} buttonName={"Scan LC Qty."} resultOnScanSuccess={lcQtyResult} />
          </label>
          <input
            type="number" id="lcQty" value={jamPreparationData.lcQty} name="lcQty"
            onChange={(e) => handleInputDataOfJamPreparation(e.target.name, e.target.value)}
            className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Input LC Quantity"
            required
            min={0}
          />
        </div>

        <div className='py-4'>
          <button
            type="submit"
            className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Submit
          </button>

          <button
            type="button"
            onClick={() => clearAll()}
            className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Clear All
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateNewJam;

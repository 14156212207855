import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchDataOfAllModules } from "../../../reduxStore/Dashboard/DashboardSlice";
import { NavLink } from "react-router-dom";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)


const AllModuleDataChart = () => {
    const state = useSelector((state) => state.dashboardReducer);
    const dispatch = useDispatch();
    const [getAllDataFromCollection, setGetAllDataFromCollection] = useState({});

    useEffect(() => {
        dispatch(fetchDataOfAllModules());
    }, [dispatch]);

    useEffect(() => {
        if (state && state.data && state.data.allModuleData) {
            setGetAllDataFromCollection(state.data.allModuleData);
        }
    }, [state]);

    const barChartData = {
        labels: ["Jam Preparation", "LC Preparation", "LC Rejection", "Bag Inoculation", "Bag Incubation", "Mould Filling", "Mold Incubation", "De-Moulding Rejection", "Oven In", "Oven Out"],
        datasets: [
            {
                label: "Pieces made by different departments.",
                data: [
                    getAllDataFromCollection.totalCountDataOfPreparedJam || 0,
                    getAllDataFromCollection.totalCountDataOfPreparedLc || 0,
                    getAllDataFromCollection.totalCountDataOfRejectedLc || 0,
                    getAllDataFromCollection.totalCountDataOfBagInoculationData || 0,
                    getAllDataFromCollection.totalCountDataOfRejectedBag || 0,
                    getAllDataFromCollection.totalCountDataOfMouldFillingData || 0,
                    getAllDataFromCollection.totalCountDataOfMouldRejectedData || 0,
                    getAllDataFromCollection.totalCountDataOfDeMouldingRejection || 0,
                    getAllDataFromCollection.totalCountDataOfOvenInData || 0,
                    getAllDataFromCollection.totalCountDataOfOvenOutData || 0,
                ],
                backgroundColor: [

                    "rgba(255, 99, 132, 0.9)",
                    "rgba(54, 162, 235, 0.9)",
                    "rgba(255, 206, 86, 0.9)",
                    "rgba(75, 192, 192, 0.9)",
                    "rgba(153, 102, 255, 0.9)",
                    "rgba(153, 102, 255, 0.9)",
                    "rgba(153, 102, 255, 0.9)",


                ],
                borderColor: [
                    "rgba(255, 99, 132,1)",
                    "rgba(54, 162, 235,1)",
                    "rgba(255, 206, 86,1)",
                    "rgba(75, 192, 192,1)",
                    "rgba(153, 102, 255,1)",
                    "rgba(153, 102, 255,1)",
                    "rgba(153, 102, 255,1)",
                ],
                borderWidth: 1,
            },
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "bottom",
            },
            title: {
                display: true,
            },
        },
    }
    return (
        <>

            <div className=" p-4 my-4 shadow border border-gray rounded relative ">

                <div className="flex justify-between">

                    <div className="font-bold text-lg text-black">All Modules</div>

                    <div className="flex justify-center items-center p-2 border rounded-lg bg-gray-100">
                        <div class="flex space-x-4">
                            <button class="px-4 py-2 bg-green-500 text-white rounded-md">Today</button>
                            <button class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md">Yesterday</button>
                            <button class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md">Starting Date</button>
                            <button class="px-4 py-2 bg-gray-200 text-gray-800 rounded-md">End Date</button>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center max-h-[500px]">
                    <Bar options={options} data={barChartData} />
                </div>

                {/* <NavLink  to={"/admin/dashboard"} className="absolute bottom-0 right-0 m-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Analytics
                    <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                </NavLink> */}

                <NavLink to="/admin/dashboard" className={({ isActive }) =>
                    isActive ? "hidden" : "absolute bottom-0 right-0 m-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                }
                >
                    Analytics
                    <svg className="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                    </svg>
                </NavLink>

            </div>
        </>
    )
}

export default AllModuleDataChart

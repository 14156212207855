import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchToCreateNewMouldFilling } from '../../reduxStore/MouldFilling/MouldFillingSlice';
import { fetchToGetTypeOfMould } from '../../reduxStore/MouldFilling/TypeOfMouldSlice';
import QRScanner from '../../assets/QRScanner';

const CreateMouldFilling = () => {
    const dispatch = useDispatch();
    const [mouldFillingData, setMouldFillingData] = useState({
        mouldId: "",
        bagId: "",
        typeOfMould: "",
    });

    const handleInputDataOfMouldFilling = (key, value) => {
        setMouldFillingData({ ...mouldFillingData, [key]: value });
    };

    const submitFilledMouldFillingData = async (e) => {
        e.preventDefault();
        try {
            await dispatch(fetchToCreateNewMouldFilling(mouldFillingData));
            setMouldFillingData({
                mouldId: "",
                bagId: mouldFillingData.bagId,
                typeOfMould: mouldFillingData.typeOfMould,
            });
        } catch (error) {
            console.log(error);
        }
    };

    const mouldIDResult = (result) => {
        setMouldFillingData({ ...mouldFillingData, mouldId: result });
    };

    const bagIDResult = (result) => {
        setMouldFillingData({ ...mouldFillingData, bagId: result });
    };

    const clearAll = async () => {
        setMouldFillingData({
            mouldId: "",
            bagId: "",
            typeOfMould: "",
        });
    };



    const stateTypeOfMould = useSelector((state) => state.TypeOfMouldReducer);
    const [typeOfMouldCollection, setTypeOfMouldCollection] = useState([]);


    useEffect(() => {
        dispatch(fetchToGetTypeOfMould());
    }, [dispatch]);


    useEffect(() => {
        if (stateTypeOfMould && stateTypeOfMould.data && stateTypeOfMould.data.typeOfMouldData) {
            setTypeOfMouldCollection(stateTypeOfMould.data.typeOfMouldData);
        }
    }, [stateTypeOfMould]);

    console.log("typeOfMouldCollection", typeOfMouldCollection);



    return (
        <>
            <form onSubmit={submitFilledMouldFillingData} className="max-w-sm bg-teal-50 p-4 sm:mx-auto mx-4 my-4 border border-teal-200 rounded-lg shadow-lg justify-center">
                <label htmlFor="mouldId" className="block mt-2 text-sm font-medium text-teal-900">
                    Scan Mould ID
                    <QRScanner idName={"mouldIdScanner"} buttonName={"Scan Mould ID"} resultOnScanSuccess={mouldIDResult} />
                </label>
                <input
                    type="text" id="mouldId" value={mouldFillingData.mouldId} name="mouldId"
                    onChange={(e) => handleInputDataOfMouldFilling(e.target.name, e.target.value)}
                    className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                    placeholder="Input Mould ID"
                    required
                />

                <label htmlFor="bagId" className="block mt-2 text-sm font-medium text-teal-900">
                    Scan Bag ID
                    <QRScanner idName={"bagIdScanner"} buttonName={"Scan Bag ID"} resultOnScanSuccess={bagIDResult} />
                </label>
                <input
                    type="text" id="bagId" value={mouldFillingData.bagId} name="bagId"
                    onChange={(e) => handleInputDataOfMouldFilling(e.target.name, e.target.value)}
                    className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                    placeholder="Input Bag ID"
                    required
                />

                <label htmlFor="typeOfMould" className="block mt-2 text-sm font-medium text-teal-900">
                    Type of Mould
                </label>

                <select
                    id="typeOfMould"
                    value={mouldFillingData.typeOfMould}
                    name="typeOfMould"
                    onChange={(e) => handleInputDataOfMouldFilling(e.target.name, e.target.value)}
                    className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                    required
                >
                    <option value="">Select Mould Type...</option>
                    {typeOfMouldCollection.map((currItem) => (
                        <option key={currItem.typeOfMould} value={currItem.typeOfMould}>
                            {currItem.typeOfMould}
                        </option>
                    ))}
                </select>
                <div className='py-4'>
                    <button
                        type="submit"
                        className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    >
                        Submit
                    </button>

                    <button
                        type="button"
                        onClick={() => clearAll()}
                        className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    >
                        Clear All
                    </button>
                </div>
            </form>
        </>
    );
};

export default CreateMouldFilling;

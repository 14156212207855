import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchToRejectNewMould } from '../../reduxStore/MouldIncubation/MouldIncubationSlice';
import QRScanner from '../../assets/QRScanner';

const RejectNewMould = () => {
  const dispatch = useDispatch();
  const [mouldData, setMouldData] = useState({
    mouldId: "",
    reasonOfRejection: "",
    remarks: "",
  });

  const handleInputData = (key, value) => {
    setMouldData({ ...mouldData, [key]: value });
  };

  const submitMouldData = async (e) => {
    e.preventDefault();
    const submissionData = {
      mouldId:mouldData.mouldId,
      reasonOfRejection: mouldData.reasonOfRejection === "Other" 
      ? `Other: ${mouldData.remarks}`
      : mouldData.reasonOfRejection,
    }
    try {
      const response =  await dispatch(fetchToRejectNewMould(submissionData)).unwrap();
      if (response.success) {
        setMouldData({
          mouldId: "",
          reasonOfRejection: "",
          remarks: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearAll = () => {
    setMouldData({
      mouldId: "",
      reasonOfRejection: ""
    });
  };

  const mouldIDResult = (result) => {
    setMouldData({ ...mouldData, mouldId: result });
  };

  return (
    <form onSubmit={submitMouldData} className="max-w-sm bg-teal-50 p-4 sm:mx-auto mx-4 my-4 border border-teal-200 rounded-lg shadow-lg justify-center">
      <label htmlFor="mouldId" className="block mt-2 text-sm font-medium text-teal-900">
        Scan Mould ID
        <QRScanner idName={"mouldIdScanner"} buttonName={"Scan Mould ID"} resultOnScanSuccess={mouldIDResult} />
      </label>
      <input
        type="text" id="mouldId" value={mouldData.mouldId} name="mouldId"
        onChange={(e) => handleInputData(e.target.name, e.target.value)}
        className="bg-teal-900 border border-teal-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Input Mould ID"
        required
      />



      <label htmlFor="reasonOfRejection" className="block mt-2 text-sm font-medium text-teal-900">
        Reason of Rejection
      </label>
      <select id="reasonOfRejection" value={mouldData.reasonOfRejection} name="reasonOfRejection"
        onChange={(e) => handleInputData(e.target.name, e.target.value)}
        className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-teal-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        required
      >
        <option value="">Select Reason...</option>
        <option value="Mold Break during Demolding">Mold Break during Demolding</option>
        <option value="Green Mold">Green Mold</option>
        <option value="Red Mold">Red Mold</option>
        <option value="Black Mold">Black Mold</option>
        <option value="No growth">No growth</option>
        <option value="Patchy Growth">Patchy Growth</option>
        <option value="Partial Growth">Partial Growth</option>
        <option value="Other">Other</option>
      </select>

      {mouldData.reasonOfRejection === "Other" && (
        <>
          <label htmlFor="remarks" className="block mt-2 text-sm font-medium text-teal-900">
            Remarks for other
          </label>
          <input
            type="text"
            id="remarks"
            value={mouldData.remarks}
            name="remarks"
            onChange={(e) => handleInputData('remarks', e.target.value)}
            className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
            placeholder="Pink Mold"
            required
          />
        </>
      )}

      <div className='py-4'>
        <button
          type="submit"
          className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        >
          Submit
        </button>

        <button
          type="button"
          onClick={() => clearAll()}
          className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        >
          Clear All
        </button>
      </div>
    </form>
  );
};

export default RejectNewMould;


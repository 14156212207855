import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { fetchToLogin } from '../reduxStore/authentication/userAuthSlice';
import { Navigate } from "react-router-dom";


const Login = ({loggedIn}) => {

  const dispatch = useDispatch();
  
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLoginData = async (e) => {
    e.preventDefault();
    try {
      const response  = await dispatch(fetchToLogin({loginDetails:formData})).unwrap();
    
      loggedIn(response.dhaDbTkn);
      <Navigate to="/" /> // Redirect on successful login
    } catch (error) {
      console.log("Error in login", error);
      // Handle specific errors if needed
    }
  };

  return (
    <div className="flex flex-col justify-center items-center mt-12 mx-2">
      <form
        onSubmit={handleLoginData}
        className="bg-white p-10 rounded shadow-lg max-[425px]:w-[90%]"
      >
        <h2 className="text-2xl mb-5 font-bold">Login</h2>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-gray-700 font-bold mb-2"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="border border-teal-300 rounded-md px-4 py-2 w-full"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleFormChange}
            required
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor="password"
            className="block text-gray-700 font-bold mb-2"
          >
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            className="border border-teal-300 rounded-md px-4 py-2 w-full"
            placeholder="Enter your password"
            value={formData.password}
            onChange={handleFormChange}
            required
          />
          <button
            type="button"
            className="text-sm text-teal-500 mt-3"
          >
            No account? Contact admin.
          </button>
        </div>
        <button
          type="submit"
          className="w-full bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Login
        </button>
      </form>
      <p className="text-sm text-gray-500 mt-3">
        Welcome to Dharaksha Ecosolutions
      </p>
    </div>
  );
};

export default Login;



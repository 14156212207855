import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const fetchToCheckOvenOutIdExist = createAsyncThunk('ovenout/fetchToCheckOvenOutIdExist', async (id, { rejectWithValue }) => {
    try {
        const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/checkExistenceOfOvenOutFG`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ovenOutFGIds: id }),
            credentials: 'include'
        });

        if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.message);
            return rejectWithValue(errorData.message || 'Failed to check ID existence.');
        }

        const data = await response.json();
        toast.success(data.message);
        return data;

    } catch (error) {
        console.error('Error while checking ID existence in Ovenout: ', error);
        return rejectWithValue(error.message);
    }
});

export const fetchToAddNewOvenOutBatch = createAsyncThunk('ovenout/fetchToAddNewOvenOutBatch', async (newBatchData, { rejectWithValue }) => {
    try {
        console.log("newBatchData",newBatchData);
        const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/ovenOutNewBatchOfFg`;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newBatchData),
            credentials: 'include'
        });

        if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.message);
            return rejectWithValue(errorData.message || 'Failed to add new batch of finished goods.');
        }

        const data = await response.json();
        toast.success(data.message);
        return data;

    } catch (error) {
        console.error('Error while adding new batch to Ovenout: ', error);
        return rejectWithValue(error.message);
    }
});

export const fetchAllOvenOutData = createAsyncThunk('ovenout/fetchAllOvenOutData', async (queryParams, { rejectWithValue }) => {
    try {

        console.log("queryParams",queryParams);
        const params = new URLSearchParams(queryParams);
        console.log("params",params);
        const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/getDataOfOvenOut?${params}`;

        const response = await fetch(url, {
            method: 'GET',
            credentials: 'include',
        });

        if (!response.ok) {
            return rejectWithValue('Failed to fetch all Ovenout data.');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error while fetching all Ovenout data: ', error);
        return rejectWithValue(error.message);
    }
});

export const fetchToDeleteOvenOutData = createAsyncThunk('ovenout/fetchToDeleteOvenOutData', async (id, { rejectWithValue }) => {
    try {
        const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/admin/delOvenOutFG/${id}`;

        const response = await fetch(url, {
            method: 'DELETE',
            credentials: 'include',
        });

        if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.message);
            return rejectWithValue(errorData.message || 'Failed to delete Ovenout data.');
        }

        const data = await response.json();
        toast.success(data.message);
        return data;

    } catch (error) {
        console.error('Error while deleting Ovenout data: ', error);
        return rejectWithValue(error.message);
    }
});

export const fetchToUpdateOvenOutData = createAsyncThunk('ovenout/fetchToUpdateOvenOutData', async ({ id, updatedData }, { rejectWithValue }) => {
    try {
        const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/admin/updateOvenOutFGData/${id}`;

        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
            credentials: 'include',
        });

        if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.message);
            return rejectWithValue(errorData.message || 'Failed to update Ovenout data.');
        }

        const data = await response.json();
        toast.success(data.message);
        return data;

    } catch (error) {
        console.error('Error while updating Ovenout data: ', error);
        return rejectWithValue(error.message);
    }
});

const ovenoutSlice = createSlice({
    name: 'ovenout',
    initialState: {
        data: null,
        isLoading: false,
        isError: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // For checking ID existence in Ovenout
            .addCase(fetchToCheckOvenOutIdExist.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchToCheckOvenOutIdExist.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(fetchToCheckOvenOutIdExist.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })
            // For adding new batch of finished goods
            .addCase(fetchToAddNewOvenOutBatch.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchToAddNewOvenOutBatch.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchToAddNewOvenOutBatch.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })

            // For fetching all Ovenout data
            .addCase(fetchAllOvenOutData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAllOvenOutData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchAllOvenOutData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })

            // For deleting Ovenout data
            .addCase(fetchToDeleteOvenOutData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchToDeleteOvenOutData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchToDeleteOvenOutData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            })

            // For updating Ovenout data
            .addCase(fetchToUpdateOvenOutData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchToUpdateOvenOutData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchToUpdateOvenOutData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
            });
    },
});

export default ovenoutSlice.reducer;

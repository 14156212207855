import React from 'react'
import Navbar from '../../assets/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProfileCard from '../../assets/Profile'
import Login from '../../assets/Login'
import Logout from '../../assets/Logout'
import DataOfOvenOut from './DataOfOvenOut'
import OvenOutNewFGBatch from './OvenOutNewFGBatch'

const NavigateInMouldIncubation = () => {
    return (
        <>
            <BrowserRouter>
                <Navbar moduleName={"Oven Out"} home={"/"} viewData={"/viewDataOfOvenOut"} />
                <Routes>
                    <Route path="/" element={<OvenOutNewFGBatch />} />
                    <Route path="/viewDataOfOvenOut" element={<DataOfOvenOut />} />
                    <Route path="/myProfile" element={<ProfileCard />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default NavigateInMouldIncubation

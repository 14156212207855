import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../assets/Loader';
import { fetchToGetRejectedDeMouldData, fetchToDeleteRejectedDeMouldData } from '../../../reduxStore/RejectedDeMoulding/RejectedDeMouldingSlice';
import FormatTime from '../../../assets/FormatTime'

const AdminDataOfRejectedDeMoulding = () => {
    const state = useSelector((state) => state.RejectedDeMouldingReducer); // Adjusted the selector name
    const dispatch = useDispatch();
    const [getAllDataFromCollection, setGetAllDataFromCollection] = useState([]);

    useEffect(() => {
        dispatch(fetchToGetRejectedDeMouldData());
    }, [dispatch]);

    useEffect(() => {
        if (state && state.data && state.data.rejectedDeMouldData) {
            setGetAllDataFromCollection(state.data.rejectedDeMouldData);
        }
    }, [state]);

    const deleteData = async (id) => {
        if (window.confirm("Do you really want to delete?")) {
            
            dispatch(fetchToDeleteRejectedDeMouldData(id)).then(() => {
                dispatch(fetchToGetRejectedDeMouldData());
            });
        }
    }

    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block">
                    <div className="border rounded-lg shadow overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">De-Mould ID</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">De-Moulding Rejection Date</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">De-Mould Rejected By</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Type Of Mould</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Mould Filling Date</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Bag ID</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Bag Inoculation Date</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC/Jam ID</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC/Jam Preparation Date</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Remove</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {state.isLoading &&
                                    <tr>
                                        <td colSpan={'8'} className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                }

                                {(Array.isArray(getAllDataFromCollection) ? getAllDataFromCollection : [] ).map((currData) => (
                                    <tr className='hover:bg-teal-300' key={currData._id}>
                                        
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.rejectedDeMouldId}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData.rejectedDeMouldingTime)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.deMouldingRejectedBy}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.previousDetailsTillMouldFilling?.typeOfMould}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData.previousDetailsTillMouldFilling?.fillingDate)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.previousDetailsTillMouldFilling?.bagId}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData.previousDetailsTillMouldFilling?.previousStatusTillBagInoculation.inoculationTimeStamp)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.previousDetailsTillMouldFilling?.previousStatusTillBagInoculation.JamOrLcId}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData.previousDetailsTillMouldFilling?.previousStatusTillBagInoculation.previousStatusTillLCPreparation?.jamPreparationTime)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                    <button type="button" onClick={() => { deleteData(currData._id) }}  className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                                        Remove
                                                    </button>
                                                </td>
                                        
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminDataOfRejectedDeMoulding

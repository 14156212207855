import React, { useEffect, useState } from 'react'
import AdminDataOfOvenin from './AdminDataOfOvenIn'
import FormatTime from '../../../assets/FormatTime';
import FilterData from '../../../assets/FilterData';
import ExportInExcel from '../../../assets/ExportInExcel';
import Pagination from '../../../assets/Pagination';
import {  useSelector } from 'react-redux';
import { fetchAllOvenInData } from '../../../reduxStore/OvenIn/OvenInSlice';
import OvenTopBar from './OvenTopBar';

const AdminOvenIn = () => {
  const state = useSelector((state) => state.ovenInReducer);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [totalNumberOfData, setTotalNumberOfData] = useState(0); // Ensure initialized to 0
  const [resultPerPage, setResultPerPage] = useState(0); // Ensure initialized to 0
  const extraFilters = {
    "ovenInSemiFGIds": "Semi FG ID",
    "batchIdOfOvenIn": "Oven In Batch Id",
    "ovenInBy": "Oven In By",
  };


  useEffect(() => {
    if (state?.data?.ovenInData) {
      setAllData(state.data.ovenInData);
      setTotalNumberOfData(state.data.totalOvenInSemiFG || 0); // Fallback to 0 if undefined
      setResultPerPage(state.data.resultPerPage || 0); // Fallback to 0 if undefined
    }
  }, [state]);

  useEffect(() => {
    const transformData = allData.map(item => ({
      "OVEN IN SEMI FG IDS": item.ovenInSemiFGIds,
      "BATCH ID OF OVEN IN": item.batchIdOfOvenIn,
      "OVEN IN TIME": FormatTime(item.ovenInTime),
      "OVEN IN BY": item.ovenInBy,
      "HUMIDIFIER IN TIME": FormatTime(item.previousDetailsTillHumidifier.jamPreparationTime),
    }));

    setData(transformData);
  }, [allData]);

  return (
    <>
     <OvenTopBar />
     <div className='flex justify-between px-3 py-4'>
        <FilterData extraFilters={extraFilters} getData={fetchAllOvenInData} />
        <ExportInExcel data={data} getData={fetchAllOvenInData} />
      </div>
    <AdminDataOfOvenin/>
    <Pagination getData={fetchAllOvenInData} totalData={totalNumberOfData} resultPerPage={resultPerPage} />


      
    </>
  )
}

export default AdminOvenIn

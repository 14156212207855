import React, { useEffect, useState } from 'react'
import FilterData from '../../../assets/FilterData'
import ExportInExcel from '../../../assets/ExportInExcel'
import Pagination from '../../../assets/Pagination'
import { useSelector } from "react-redux";
import { fetchBagIncubationData } from '../../../reduxStore/Incubation/IncubationSlice';
import FormatTime from '../../../assets/FormatTime';
import AdminDataOfBagStoreRoom from './AdminDataOfBagStoreRoom'
import RejectNewBag from '../../BagStoreRoom/RejectNewBag';
import DataOfBagStoreRoom from '../../BagStoreRoom/DataOfBagStoreRoom';


const AdminBagStoreRoom = () => {
  const state = useSelector((state) => state.bagIncubationReducer)
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [totalNumberOfData, setTotalNumberOfData] = useState(0); // Ensure initialized to 0
  const [resultPerPage, setResultPerPage] = useState(0); // Ensure initialized to 0

  const extraFilters = {
    "bagId": "Bag Id",
    "reasonOfRejection": "Reason Of Rejection",
    "bagRejectedBy": "Bag Rejected By "

  };


  useEffect(() => {
    if (state?.data?.dataOfRejectedBag) {
      setAllData(state.data.dataOfRejectedBag);
      setTotalNumberOfData(state.data.totalBagRejectedData || 0); // Fallback to 0 if undefined
      setResultPerPage(state.data.resultPerPage || 0); // Fallback to 0 if undefined
    }
  }, [state]);


  useEffect(() => {
    const transformData = allData.map(item => ({
      "Bag ID": item.bagId,
      "Reason Of Rejection": item.reasonOfRejection,
      "Bag Rejected By": item.bagRejectedBy,
      "Bag Quantity(in gm.)": item.previousStatusTillBagInoculation?.bagQty,
      "LC/Jam ID": item.previousStatusTillBagInoculation?.JamOrLcId,
      "LC/Jam Quantity(in ml.)": item.previousStatusTillBagInoculation?.JamOrLcQty,
      "Bag Inoculation Time": FormatTime(item.previousStatusTillBagInoculation?.inoculationTimeStamp),
      "Bag Rejection Time": FormatTime(item.bagRejectionTime),
    }));

    setData(transformData);
  }, [allData]);



 

  return (
    <>



      <div className='flex justify-between px-3  py-4'>
        <FilterData extraFilters={extraFilters} getData={fetchBagIncubationData} />
        <ExportInExcel data={data} getData={fetchBagIncubationData}/>


      </div>

      <AdminDataOfBagStoreRoom />

      <Pagination getData={fetchBagIncubationData} totalData={totalNumberOfData} resultPerPage={resultPerPage} />

 
    </>
  )
}

export default AdminBagStoreRoom

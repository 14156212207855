import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast } from "react-toastify";
// import { useDispatch, useSelector } from 'react-redux';
import FormatTime from '../../assets/FormatTime';
import { useDispatch, useSelector } from "react-redux";
import { getAddedMouldsData } from '../../reduxStore/Humidifier/HumidifierSlice'

const TakeReading = () => {
    const state = useSelector((state) => state.growthChamberReducer)
    const dispatch = useDispatch();


    const location = useLocation();
    const navigate = useNavigate();

    const [humidifier, setHumidifier] = useState(location.state.humidifier);
    const [addedMouldData, setAddedMouldsData] = useState({});
    const [readingData, setReadingData] = useState({
        temp: "",
        humidity: ""
    });

    const handleReadingData = (key, value) => {
        setReadingData({ ...readingData, [key]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST_URL;
            // const url = `${BACK_HOST}humidifier/moulds?id=${humidifier.dataId}`;
            const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
            const url = `${BACK_HOST}api/v1/humidifier/moulds?id=${humidifier.dataId}`;
            let data = {
                'humidityReading': readingData.temp,
                'temperatureReading': readingData.humidity
            };
            console.log("Data: ", data)
            const response = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            })
            // console.log("response: ", response)
            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.message);
                return errorData
            }



            const res = await response.json();
            console.log("submit res:", res);
            // toast.success(res.message);
            toast.success(res.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setReadingData({
                temp: "",
                humidity: ""
            });
            // getAddedMouldsData()
            dispatch(getAddedMouldsData({ humidifier }))
            return res;

        } catch (error) {
            console.error("Error in adding moulds ", error);
            throw error;
        }
    }




    // const getAddedMouldsData = async () => {
    //     try {
    //         // const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST_URL;
    //         // const url = `${BACK_HOST}humidifier/moulds?id=${humidifier.dataId}`;
    //         const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
    //         const url = `${BACK_HOST}api/v1/humidifier/moulds?id=${humidifier.dataId}`;
    //         const response = await fetch(url, {
    //             method: "GET",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             }
    //         })
    //         if (!response.ok) {
    //             const errorData = await response.json();
    //             toast.error(errorData.message);
    //             return errorData
    //         }
    //         const res = await response.json();
    //         console.log("Added mould data :", res);
    //         setAddedMouldsData(res.data);
    //         toast.success(res.message);
    //         return res;
    //     } catch (error) {
    //         console.error("Error in fetching moulds data", error);
    //         throw error;
    //     }
    // }

    const removeReading = async (indexNo) => {
        try {
            const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
            const url = `${BACK_HOST}api/v1/humidifier/moulds/removeReadingData?id=${humidifier.dataId}&indexNo=${indexNo}`;
            const response = await fetch(url, {
                method: "DELETE",
            })
            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.message);
                return errorData
            }
            const res = await response.json();
            console.log("Removing Reading data :", res);
            // getAddedMouldsData()
            dispatch(getAddedMouldsData({ humidifier }))
            toast.success(res.message);
            return res;
        } catch (error) {
            console.error("Error in removing reading data", error);
            throw error;
        }
    }



    const allReadingTaken = async () => {
        const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        console.log("humidifier._id", humidifier._id);
        const url = `${BACK_HOST}api/v1/humidifier/readingsFinished?id=${humidifier._id}`;
        console.log("url", url);
        try {
            const response = await fetch(url, {
                method: 'PUT'
            })
            // console.log(" Completely Filled response: 1", response)
            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.message);
                return errorData
            }

            const res = await response.json();
            // console.log(" Completely Filled response: 2", res)
            toast.success(res.message);
            navigate('/')
          
            return res;

        } catch (error) {
            console.error("Error in Humidifier filled completely ", error);
            throw error;
        }
    }


    useEffect(() => {
        dispatch(getAddedMouldsData({ humidifier }));
      }, [dispatch]);
    
      useEffect(() => {
        if (state && state.data && state.data.data) {
          setAddedMouldsData(state.data.data);
        }
      }, [state]);

    return (
        <>
            <div class="max-w-sm mx-auto bg-teal-50 p-4 border border-teal-200 rounded-lg shadow-lg mt-2 mb-2">
                <h2 className='text-3xl font-bold m-4 text-center'>Humidifier : {humidifier.serialNo}</h2>
                <div className='flex w-full justify-between items-center'>
                    <h5 className="text-lg font-semibold text-teal-900">Reading no: {addedMouldData.temperatureReading && (addedMouldData.temperatureReading).length + 1}</h5>
                    <button type="button" onClick={allReadingTaken} class="ml-0 text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Readings Finished</button>

                </div>
                <form >
                    <div>
                        <label for="scanMultipleMouldIds" class="block mb-2 text-sm font-medium text-teal-900 ">Temperature(°C)</label>
                        <input type="Number" value={readingData.temp} min={0} max={100}
                            id="Number" name="temp" className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onChange={(e) => handleReadingData(e.target.name, e.target.value)}
                            placeholder="Input Temperature" required />
                    </div>
                    <div>
                        <label for="scanMultipleMouldIds" class="block mb-2 text-sm font-medium text-teal-900 ">Humidity(%)</label>
                        <input type="Number" value={readingData.humidity} min={0} max={100}
                            id="Number" name="humidity" class="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Input Humidity" onChange={(e) => handleReadingData(e.target.name, e.target.value)} required />
                    </div>



                    <div className='p-2'>
                        <button type="button" onClick={(e) => handleSubmit(e)} className="text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Submit</button>

                        <button type="button" class="text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" onClick={(e) => {
                            navigate('/')
                        }} >Cancel</button>
                    </div>
                </form>
                {
                    // if required show the added moulds data and their reading
                }
            </div>


            <div className="relative overflow-x-auto shadow-md rounded-lg mx-4 mt-2 mb-2">

                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 ">
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        <tr className='hover:bg-teal-300'>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border text-lg font-bold">Batch ID :</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{addedMouldData?.batchId}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800  text-lg font-bold">Mould IDs:</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border col-span-6" colSpan="6">
                                {addedMouldData?.moldIds?.join(', ')}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>



            <div className='flex flex-col m-4'>
                <div className='font-bold text-3xl text-center w-[100%]'>Reading data</div>
                <div className="relative overflow-x-auto shadow-md rounded-lg mt-2 mb-2">
                    <table className="min-w-full divide-y divide-gray-200 ">

                        <thead className="bg-gray-50 ">
                            <tr>
                                <th className="px-6 py-6  text-sm font-medium text-gray-500 uppercase border">Reading No</th>
                                {/* <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Batch ID</th> */}
                                {/* <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Mould Ids</th> */}
                                <th className="px-6 py-6  text-sm font-medium text-gray-500 uppercase border">Temperature</th>
                                <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Humidity</th>
                                <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Time</th>
                                <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Remove</th>
                            </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-200">

                            {addedMouldData && addedMouldData.temperatureReading && addedMouldData.temperatureReading.map((temp, ind) => (
                                <tr className='hover:bg-teal-300' key={ind}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{ind + 1}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{temp}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{addedMouldData.humidityReading[ind]}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(addedMouldData.readingTime[ind])}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                        <button type="button" onClick={() => removeReading(ind)} className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">Remove</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </div>
            </div>
        </>
    )
}

export default TakeReading

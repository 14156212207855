import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { toast } from "react-toastify";
import FormatTime from '../../assets/FormatTime';
import { useDispatch, useSelector } from "react-redux";
import { getAddedMouldsData } from '../../reduxStore/Humidifier/HumidifierSlice'

const RemoveMould = () => {
    const state = useSelector((state) => state.growthChamberReducer)
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [humidifier, setHumidifier] = useState(location.state.humidifier);
    console.log("location.state", location);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [addedMouldData, setAddedMouldsData] = useState({});
    const [growthType, setgrowthType] = useState();
    const handleFileChange = (event) => {
        // Get the selected files from the input element
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
    };

    useEffect(() => {
        dispatch(getAddedMouldsData({ humidifier }));
      }, [dispatch]);
    
      useEffect(() => {
        if (state && state.data && state.data.data) {
          setAddedMouldsData(state.data.data);
        }
      }, [state]);



    const uploadImagesAndGrowthType = async () => {
        // Create a new FormData object

        // Append each file to the FormData object
        selectedFiles.forEach(async (file, index) => {
            const formData = new FormData();
            formData.append(`image`, file);
            try {
                // Make the request to the API
                // const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST_URL;
                const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
                // const url = `${BACK_HOST}humidifier/uploadImages?id=${humidifier.dataId}`;
                const url = `${BACK_HOST}api/v1/humidifier/uploadImages?id=${humidifier.dataId}`;
                let content_length = JSON.stringify(formData).length;
                console.log("Content length : ", content_length)
                const response = await fetch(url, {
                    method: 'POST',
                    body: formData,
                    // headers: {
                    // "Content-Type": "application/json",
                    // 'Content-Length': content_length
                    // }
                });
                // Check if the response is successful
                if (response.ok) {
                    const data = await response.json();
                    console.log('Images uploaded successfully:', data);
                    // return data;
                } else {
                    console.error('Image upload failed:', response.status, response.statusText);
                    return null;
                }
            } catch (error) {
                console.error('an error occurred:', error);
                return null;
            }
        });
        uploadTypeOFGrowth();
        // return 0;
    };
    const uploadTypeOFGrowth = async () => {
        // e.preventDefault();
        try {
            // const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST_URL;
            const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
            // const url = `${BACK_HOST}humidifier/updateMould?id=${humidifier.dataId}`;
            const url = `${BACK_HOST}api/v1/humidifier/updateMould?id=${humidifier.dataId}`;
            let data = {
                'growthType': growthType
            };
            console.log("Data: ", data)
            const response = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            })
            // console.log("response: ", response)
            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.message);
                return errorData
            }

            const res = await response.json();
            console.log("submit res:", res);
            // toast.success(res.message);
            toast.success(res.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            return res;

        } catch (error) {
            console.error("Error in adding moulds ", error);
            throw error;
        }
    }
    const updateHumidifier = async () => {
        // e.preventDefault();
        try {
            // const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST_URL;
            const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
            // const url = `${BACK_HOST}humidifier?id=${humidifier.serialNo}`;
            const url = `${BACK_HOST}api/v1/humidifier?id=${humidifier.serialNo}`;
            let data = {
                'status': 'empty'
            };
            if (humidifier.cycleCount % 6 === 0) {
                data.status = 'clean'
            }
            console.log("Data: ", data)
            const response = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            })
            // console.log("response: ", response)
            if (!response.ok) {
                const errorData = await response.json();
                toast.error(errorData.message);
                return errorData
            }

            const res = await response.json();
            console.log("submit res:", res);
            return res;

        } catch (error) {
            console.error("Error in updating humidifier ", error);
            throw error;
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        uploadImagesAndGrowthType();
        updateHumidifier();
        navigate('/');
        // console.log("resp :",res)
    };


    return (
        <>
            <div class="max-w-sm mx-auto bg-teal-50 p-4 border border-teal-200 rounded-lg shadow-lg mt-2 mb-2">
                <div >
                    <h1 className='text-3xl font-bold'>
                        Humidifier : {humidifier.serialNo}
                    </h1>
                    {addedMouldData.temperatureReading && <h5 className='text-md font-semibold my-2'>No. of readings taken : {(addedMouldData.temperatureReading).length} </h5>}
                </div>
                <form >
                    <div>
                        <label for="scanMultipleMouldIds" class="block mb-2 text-sm font-medium text-teal-900 ">Type of Growth(%)</label>
                        <input type="Number" id="Number" className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Type of Growth" onChange={(e) => setgrowthType(e.target.value)} value={growthType} required />
                    </div>
                    <div>
                        <label for="scanMultipleMouldIds" class="block mb-2 text-sm font-medium text-teal-900 ">Take Images</label>
                        <input type="file" id="picture" name="picture" multiple onChange={handleFileChange} accept="image/*" capture="environment" class="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                    </div>




                    <div className='p-2'>
                        <button type="button" className="text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800" onClick={handleSubmit}>Submit</button>

                        <button type="button" class="text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Cancel</button>
                    </div>



                </form>
            </div >

            <div className="relative overflow-x-auto shadow-md rounded-lg mx-4 mt-2 mb-2">

                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 ">
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        <tr className='hover:bg-teal-300'>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border text-lg font-bold">Batch ID :</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{addedMouldData?.batchId}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800  text-lg font-bold">Mould IDs:</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border col-span-6" colSpan="6">
                                {addedMouldData?.moldIds?.join(', ')}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div className='flex flex-col m-4'>
                <div className='font-bold text-3xl text-center w-[100%]'>Reading data</div>
              <div className="relative overflow-x-auto shadow-md rounded-lg mt-2 mb-2">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50 ">
                            <tr>
                                <th className="px-6 py-6  text-sm font-medium text-gray-500 uppercase border">Reading No</th>
                                {/* <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Batch Id</th> */}
                                {/* <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Mould Ids</th> */}
                                <th className="px-6 py-6  text-sm font-medium text-gray-500 uppercase border">Temperature</th>
                                <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Humidity</th>
                                <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Time</th>
                            </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-200">
                            {addedMouldData && addedMouldData.temperatureReading && addedMouldData.temperatureReading.map((temp, ind) => (
                                <tr className='hover:bg-teal-300' key={ind}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{ind + 1}</td>
                                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{addedMouldData?.batchId}</td> */}
                                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{addedMouldData.moldIds.join(', ')}</td> */}
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{temp}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{addedMouldData.humidityReading[ind]}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(addedMouldData.readingTime[ind])}</td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </div>
            </div>
        </>
    )
}

export default RemoveMould

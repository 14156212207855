import React, { useState } from 'react';
import MultipleFgIdsInput from './ScanMultiIDsOvenIn';
import Modal from '../../assets/OvenModal';
import { useDispatch } from 'react-redux';
import { fetchToAddNewOvenInBatch, fetchToCheckIsIdExist } from '../../reduxStore/OvenIn/OvenInSlice';
import { useLocation } from 'react-router-dom';

const OvenInNewSemiFGBatch = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [submitCreatedDataOfOvenIn, setSubmitCreatedDataOfOvenIn] = useState();
  const dispatch = useDispatch();

  async function submittedDataIds(newIds) {
    const result = {
      ovenInSemiFGIds: newIds
    };
    const response = await dispatch(fetchToAddNewOvenInBatch(result)).unwrap();
    const createdData = response?.createdData?.map((currItem) => currItem.ovenInSemiFGIds);
    setSubmitCreatedDataOfOvenIn(createdData);
    setIsOpen(true); 
  }

  const closeModal = () => {
    setIsOpen(false);
  };

  const checkIsIdExist = async (id) => {
    try {
      const response = await dispatch(fetchToCheckIsIdExist({ ovenInSemiFGIds: id })).unwrap();
      return response;
    } catch (error) {
      return { success: false, message: error.message };
    }
  };


  const location = useLocation()
  const [ovenDetails, setOvenDetails] = useState(location.state.oven);
  console.log("ovenDetails:",ovenDetails);


  return (
    <>
      <MultipleFgIdsInput
        scannerIdName={'ovenInScanner'}
        labelName={'Semi-FG ID:-'}
        onSubmitGetAllInputIds={submittedDataIds}
        checkIsIdExist={checkIsIdExist}
        oven={ovenDetails}
      />
      <Modal isOpen={isOpen} closeModal={closeModal} submitCreatedData={submitCreatedDataOfOvenIn}/>
    </>
  );
};

export default OvenInNewSemiFGBatch;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;

// Thunks for fetching data of various modules
export const fetchDataOfAllModules = createAsyncThunk(
  "dashboard/fetchDataOfAllModules",
  async () => {
    try {
      const url = `${BACK_HOST}api/v1/admin/getDataOfAllModules`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include"
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message);
        throw new Error("Failed to fetch data of all modules");
      }
      const data = await response.json();
      // console.log("data",data);
      return data;
    } catch (error) {
      console.log("Error fetching data of all modules:", error);
      throw error;
    }
  }
);

export const fetchDataOfLcModules = createAsyncThunk(
  "dashboard/fetchDataOfLcModules",
  async () => {
    try {
      const url = `${BACK_HOST}api/v1/admin/getDataOfLcModules`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include"
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message);
        throw new Error("Failed to fetch data of LC modules");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.log("Error fetching data of LC modules:", error);
      throw error;
    }
  }
);

export const fetchDataOfBagModules = createAsyncThunk(
  "dashboard/fetchDataOfBagModules",
  async () => {
    try {
      const url = `${BACK_HOST}api/v1/admin/getDataOfBagModules`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include"
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message);
        throw new Error("Failed to fetch data of Bag modules");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.log("Error fetching data of Bag modules:", error);
      throw error;
    }
  }
);

export const fetchDataOfMouldModules = createAsyncThunk(
  "dashboard/fetchDataOfMouldModules",
  async () => {
    try {
      const url = `${BACK_HOST}api/v1/admin/getDataOfMouldModules`;
      const response = await fetch(url, {
        method: "GET",
        credentials: "include"
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.message);
        throw new Error("Failed to fetch data of Mould modules");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.log("Error fetching data of Mould modules:", error);
      throw error;
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataOfAllModules.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDataOfAllModules.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataOfAllModules.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching data of all modules", action.error);
      });

    builder
      .addCase(fetchDataOfLcModules.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDataOfLcModules.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataOfLcModules.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching data of LC modules", action.error);
      });

    builder
      .addCase(fetchDataOfBagModules.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDataOfBagModules.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataOfBagModules.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching data of Bag modules", action.error);
      });

    builder
      .addCase(fetchDataOfMouldModules.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDataOfMouldModules.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchDataOfMouldModules.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        console.error("Error fetching data of Mould modules", action.error);
      });
  },
});

export default dashboardSlice.reducer;

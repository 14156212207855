import React from 'react';
import { Bar, Pie } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchDataOfMouldModules } from "../../../reduxStore/Dashboard/DashboardSlice";
import DateFilterButtons from './DateFilterButtons';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const MouldsModulesCharts = () => {
  const state = useSelector((state) => state.dashboardReducer);
  const dispatch = useDispatch();
  const [getAllDataFromCollection, setGetAllDataFromCollection] = useState({});

  useEffect(() => {
    dispatch(fetchDataOfMouldModules());
  }, [dispatch]);

  useEffect(() => {
    if (state && state.data && state.data.mouldModulesData) {
      setGetAllDataFromCollection(state.data.mouldModulesData);
    }
  }, [state]);

  console.log("getAllDataFromCollection", getAllDataFromCollection);

  const barChartData = {
    labels: ["Mould Filling", "Mould Rejection"],
    datasets: [
      {
        label: "Moulds Departments",
        data: [
          getAllDataFromCollection.totalCountDataOfMouldFillingData || 0,
          getAllDataFromCollection.totalCountDataOfMouldRejectedData || 0,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.9)",
          "rgba(54, 162, 235, 0.9)",
        ],
        borderColor: [
          "rgba(255, 99, 132,1)",
          "rgba(54, 162, 235,1)",
        ],
        borderWidth: 1,
        barThickness: 80, // Adjust this value to set the bar width
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
      },
    },
  };

  const pieChartData = {
    // labels:["other","Media leak from bottle","Black Mold","Orange Mold","No growth","Green Mold","Turbidity"]
    labels: getAllDataFromCollection?.rejectionReasons,
    datasets: [
      {
        label: "Reason of Rejection of Parali Bags",
        // data: [
        //   getAllDataFromCollection.rejectionReasonCounts?.["Other"] || 0,
        //   getAllDataFromCollection.rejectionReasonCounts?.["Media leak from bottle"],
        //   getAllDataFromCollection.rejectionReasonCounts?.["Black Mold"],
        //   getAllDataFromCollection.rejectionReasonCounts?.["Orange Mold"],
        //   getAllDataFromCollection.rejectionReasonCounts?.["No growth"],
        //   getAllDataFromCollection.rejectionReasonCounts?.["Green Mold"],
        //   getAllDataFromCollection.rejectionReasonCounts?.["Turbidity"],

        // ],
        data: getAllDataFromCollection?.rejectionCounts,
        backgroundColor: [
          "rgba(255, 12, 55, 1)",    
          "rgba(54, 162, 235, 1)",    
          "rgba(0, 0, 0, 1)",    
          "rgba(255, 165, 0, 1)",    
          "rgba(128, 128, 128, 1)",     
          "rgba(0, 128, 0, 1)",    
          "rgba(255, 255, 128, 1)"       

        ],
        hoverOffset: 4,
      }
    ]
  }



  return (
    <>
      <div className="p-4 my-4 shadow border border-gray-300 rounded">
        <DateFilterButtons chartName={"Moulds Modules"} />

        <div className="flex flex-wrap justify-center  p-2 justify-evenly">
          <div className='p-4 my-4 flex items-end shadow border border-gray-300 rounded min-w-[500px] max-sm:min-w-[200px]'>

            <Bar className="h-full" options={options} data={barChartData} />
          </div>
          <div className='p-4 my-4  shadow border border-gray-300 rounded min-w-[500px] max-sm:min-w-[200px]'>
            <Pie options={options} data={pieChartData} />
          </div>
        </div>
      </div>
    </>
  );
}


export default MouldsModulesCharts

import React from 'react'
import GrowthData from '../../GrowthChamber/GrowthData'
import HumidifierTopBar from './HumidifierTopBar'

const AdminHumidifierGrowthData = () => {
  return (
    <>
    <HumidifierTopBar/>
      <GrowthData/>
    </>
  )
}

export default AdminHumidifierGrowthData

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Thunk to reject a new mold
export const fetchToRejectNewMould = createAsyncThunk('mouldIncubation/fetchToRejectNewMould', async (enteredMouldData) => {
    try {
            const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/rejectNewMould`;
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(enteredMouldData),
            credentials:"include"
        });

        if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.message);
            throw new Error(errorData.message || "Failed to reject the mold.");
        }

        const data = await response.json();
        toast.success(data.message);
        return data;

    } catch (error) {
        console.error("Error while rejecting mold: ", error);
        throw error;
    }
});

// Thunk to fetch rejected mold data
export const fetchToGetRejectedMoldData = createAsyncThunk('mouldIncubation/fetchToGetRejectedMoldData', async (queryParams) => {
    try {
        const params = new URLSearchParams(queryParams);
            const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/getRejectedMoldData?${params}`;
        const response = await fetch(url, {
            method: 'GET',
            credentials:"include"
        });

        if (!response.ok) {
            throw new Error("Failed to fetch rejected mold data.");
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error("Error while fetching rejected mold data: ", error);
        throw error;
    }
});

// Thunk to delete rejected mold data
export const fetchToDeleteRejectedMouldData = createAsyncThunk('mouldIncubation/fetchToDeleteRejectedMouldData', async (id) => {
    try {
            const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/admin/deleteRejectedMouldData/${id}`;
        const response = await fetch(url, {
            method: 'DELETE',
            credentials:"include"
        });

        if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.message);
            throw new Error(errorData.message || "Failed to delete rejected mold data.");
        }

        const data = await response.json();
        toast.success(data.message);
        return data;

    } catch (error) {
        console.error("Error while deleting rejected mold data: ", error);
        throw error;
    }
});

// Thunk to update rejected mold data
export const fetchToUpdateRejectedMouldFillingData = createAsyncThunk('mouldIncubation/fetchToUpdateRejectedMouldFillingData', async ({ id, enteredData }) => {
    try {
            const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        const url = `${BACK_HOST}api/v1/admin/updateRejectedMouldFillingData/${id}`;

        const response = await fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(enteredData),
            credentials:"include"
        });

        if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.message);
            throw new Error(errorData.message || "Failed to update rejected mold data.");
        }

        const data = await response.json();
        toast.success(data.message);
        return data;

    } catch (error) {
        console.error("Error while updating rejected mold data: ", error);
        throw error;
    }
});

const mouldIncubationSlice = createSlice({
    name: "mouldIncubation",
    initialState: {
        data: null,
        isLoading: false,
        isError: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // For rejecting a new mold
            .addCase(fetchToRejectNewMould.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchToRejectNewMould.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchToRejectNewMould.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })

            // For fetching rejected mold data
            .addCase(fetchToGetRejectedMoldData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchToGetRejectedMoldData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchToGetRejectedMoldData.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })

            // For deleting rejected mold data
            .addCase(fetchToDeleteRejectedMouldData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchToDeleteRejectedMouldData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchToDeleteRejectedMouldData.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            })

            // For updating rejected mold data
            .addCase(fetchToUpdateRejectedMouldFillingData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchToUpdateRejectedMouldFillingData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
            })
            .addCase(fetchToUpdateRejectedMouldFillingData.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
            });
    },
});

export default mouldIncubationSlice.reducer;

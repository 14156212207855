import React from 'react'
import Navbar from '../../assets/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProfileCard from '../../assets/Profile'
import RejectNewDeMoulding from './RejectNewDeMoulding'
import DataOfRejectedDeMoulding from './DataOfRejectedDeMoulding'
import Logout from '../../assets/Logout'

const NavigateInRejectedDeMoulding = () => {
    return (
        <>
            <BrowserRouter>
                <Navbar moduleName={"De-Mould Rejection"} home={"/"} viewData={"/viewDataOfRejectedDeMoulding"} />
                <Routes>
                    <Route path="/" element={<RejectNewDeMoulding />} />
                    <Route path="/viewDataOfRejectedDeMoulding" element={< DataOfRejectedDeMoulding />} />
                    <Route path="/myProfile" element={< ProfileCard />} />
                    <Route path="/logout" element={<Logout />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}


export default NavigateInRejectedDeMoulding

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../../assets/Loader';
import FormatTime from '../../../assets/FormatTime';
import { fetchAllOvenOutData, fetchToDeleteOvenOutData, fetchToUpdateOvenOutData } from '../../../reduxStore/OvenOut/OvenOutSlice';


const AdminDataOfOvenOut = () => {
    const state = useSelector((state) => state.ovenOutReducer);
    const dispatch = useDispatch();

    const [getAllDataFromCollection, setGetAllDataFromCollection] = useState([]);
    const [updatingId, setUpdatingId] = useState(null);
    const [disabledButtons, setDisabledButtons] = useState(false);
    const [ovenInDataForUpdate, setOvenInDataForUpdate] = useState({});

    useEffect(() => {
        dispatch(fetchAllOvenOutData());
    }, [dispatch]);

    useEffect(() => {
        if (state && state.data && state.data.ovenOutData) {
            setGetAllDataFromCollection(state.data.ovenOutData);
        }
    }, [state]);


    const deleteData = async (id) => {
        if (window.confirm("Do you really want to delete?")) {
            dispatch(fetchToDeleteOvenOutData(id)).then(() => {
                dispatch(fetchAllOvenOutData());
            });
        }
    }

    const updateData = (id) => {
        setUpdatingId(id);
        setDisabledButtons(true);
        const updatingData = getAllDataFromCollection.find((currItem) => currItem._id === id);
        if (updatingData) {
            setOvenInDataForUpdate(updatingData);
        } else {
            console.error("No data found for the provided id");
        }
    }

    const handleInputData = (key, value) => {
        setOvenInDataForUpdate({ ...ovenInDataForUpdate, [key]: value });
    }

    const save = async (id) => {
        const { _id, __v, ovenOutTime, previousDetailsTillOvenIn, ...updatedData } = ovenInDataForUpdate;
        dispatch(fetchToUpdateOvenOutData({ id, updatedData })).then(() => {
            setUpdatingId(null);
            setDisabledButtons(false);
            setOvenInDataForUpdate({});
            dispatch(fetchAllOvenOutData());
        })
    }

    const updateCancel = () => {
        setUpdatingId(null);
        setDisabledButtons(false);
        setOvenInDataForUpdate({});
    }

    return (
        <>
            <div className="flex flex-col">
                <div className="overflow-x-auto">
                    <div className="p-1.5 min-w-full inline-block">
                        <div className="border rounded-lg shadow overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Oven Out FG IDs</th>
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">QC CATEGORIES</th>
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Oven Out Batch ID</th>
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Oven Out Time</th>
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Oven Out By</th>
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Oven In Batch ID</th>
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Oven In Time</th>
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Remove</th>
                                        <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Update</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {state.isLoading &&
                                        <tr>
                                            <td colSpan={'6'} className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-center">
                                                <Loader />
                                            </td>
                                        </tr>
                                    }

                                    {(Array.isArray(getAllDataFromCollection) ? getAllDataFromCollection : []).map((currData) => (
                                        <tr className='hover:bg-teal-300' key={currData._id}>
                                            {updatingId && currData._id === updatingId ? (
                                                <>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                        <input type="String" value={ovenInDataForUpdate.ovenOutFGIds} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="ovenOutFGIds" placeholder="Oven In Semi FG IDs" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                        <input type="String" value={ovenInDataForUpdate.ovenOutTypeOfFG} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="ovenOutTypeOfFG" placeholder="Batch ID of Oven In" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                        <input type="String" value={ovenInDataForUpdate?.batchIdOfOvenOut} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="batchIdOfOvenOut" placeholder="Batch ID of Oven In" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                        <input type="String" value={FormatTime(ovenInDataForUpdate.ovenOutTime)} name="ovenOutTime" disabled={disabledButtons} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                        <input type="String" value={ovenInDataForUpdate.ovenOutBy} onChange={(e) => handleInputData(e.target.name, e.target.value)} name="ovenOutBy" placeholder="Oven In By" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                        <input type="String" value={ovenInDataForUpdate?.previousDetailsTillOvenIn?.batchIdOfOvenIn} onChange={(e) => handleInputData(e.target.name, e.target.value)} disabled={disabledButtons} name="batchIdOfOvenIn" placeholder="Oven In By" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">
                                                        <input type="String" value={FormatTime(ovenInDataForUpdate.previousDetailsTillOvenIn?.jamPreparationTime)} name="ovenInTime" disabled={disabledButtons} onChange={(e) => handleInputData(e.target.name, e.target.value)}  placeholder="Previous Details Till Humidifier" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required />
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                        <button type="button" onClick={() => { deleteData(currData._id) }} disabled={disabledButtons} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                                            Remove
                                                        </button>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                        <button type="button" onClick={() => { save(currData._id) }} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-green-500 hover:text-green-800 disabled:opacity-50 disabled:pointer-events-none mr-2">
                                                            Save
                                                        </button>
                                                        <button type="button" onClick={() => { updateCancel(currData._id) }} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-yellow-600 hover:text-yellow-800 disabled:opacity-50 disabled:pointer-events-none">
                                                            Cancel
                                                        </button>
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData?.ovenOutFGIds}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData?.ovenOutTypeOfFG}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData?.batchIdOfOvenOut || "Missing Batch ID"}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData?.ovenOutTime)}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData?.ovenOutBy}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData?.previousDetailsTillOvenIn?.batchIdOfOvenIn || "Missing Batch ID"}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData?.previousDetailsTillOvenIn?.jamPreparationTime)}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">


                                                        <button type="button" onClick={() => { deleteData(currData._id) }} disabled={disabledButtons} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                                            Remove
                                                        </button>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium border">
                                                    

                                                        <button type="button" onClick={() => { updateData(currData._id) }} disabled={disabledButtons} className="inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none">Edit</button>

                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminDataOfOvenOut
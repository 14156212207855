import React, { useEffect, useState } from 'react';
import FilterData from '../../../assets/FilterData';
import ExportInExcel from '../../../assets/ExportInExcel';
import Pagination from '../../../assets/Pagination';
import { fetchBagInoculationData } from '../../../reduxStore/BagInoculation/BagInoculationSlice';
import { useSelector, useDispatch } from 'react-redux'; // Added useDispatch import
import AdminDataOfBagInoculation from './AdminDataOfBagInoculation';
import FormatTime from '../../../assets/FormatTime';
import DataOfBagInoculation from '../../BagInoculation/DataOfBagInoculation';
import InoculateNewBag from '../../BagInoculation/InoculateNewBag';

const AdminBagInoculation = () => {
    const dispatch = useDispatch(); // Added dispatch initialization
    const state = useSelector((state) => state.bagInoculationReducer);
    const extraFilters = {
        "bagId": "Bag Id",
        "bagQty": "Bag Qty.",
        "JamOrLcId": "Jam/Lc Id",
        "JamOrLcQty": "Jam/Lc Qty."
    };

    const [data, setData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [totalNumberOfData, setTotalNumberOfData] = useState(0); // Ensure initialized to 0
    const [resultPerPage, setResultPerPage] = useState(0); // Ensure initialized to 0

    useEffect(() => {
        dispatch(fetchBagInoculationData()); // Fetch data on component mount
    }, [dispatch]);

    useEffect(() => {
        // Check if state.data.bagInoculationData exists and is an array
        if (state?.data?.bagInoculationData && Array.isArray(state.data.bagInoculationData)) {
            setAllData(state.data.bagInoculationData);
            setTotalNumberOfData(state.data.totalBagInoculationData || 0);
            setResultPerPage(state.data.resultPerPage || 0);
        }
    }, [state]);

    useEffect(() => {
        // Transform allData if it's an array
        const transformData = Array.isArray(allData) && allData.map(item => ({
            "Jam/LC ID": item.JamOrLcId,
            "Jam/LC Quantity(in ml.)": item.JamOrLcQty,
            "Bag ID": item.bagId,
            "Bag Quantity": item.bagQty,
            "Bag Inoculated By": item.inoculatedBy,
            "Bag Inoculation Time": FormatTime(item.inoculationTimeStamp),
        }));

        setData(transformData);
    }, [allData]);

    return (
        <>
           
            <div className='flex justify-between px-3 py-4'>
                <FilterData extraFilters={extraFilters} getData={fetchBagInoculationData} />
                <ExportInExcel data={data} getData={fetchBagInoculationData} />
            </div>
            <AdminDataOfBagInoculation />
            <Pagination getData={fetchBagInoculationData} totalData={totalNumberOfData} resultPerPage={resultPerPage} />
       
        </>
    );
}

export default AdminBagInoculation;

import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchToInoculateNewBag } from '../../reduxStore/BagInoculation/BagInoculationSlice';
import QRScanner from '../../assets/QRScanner';

const InoculateNewBag = () => {
  const dispatch = useDispatch();
  const [bagInoculationData, setBagInoculationData] = useState({
    JamOrLcId: "",
    JamOrLcQty: "",
    bagId: "",
    bagQty: ""
  });

  const handleInputDataOfBagInoculation = (key, value) => {
    setBagInoculationData({ ...bagInoculationData, [key]: value });
  };

  const submitFilledBagInoculationData = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(fetchToInoculateNewBag(bagInoculationData)).unwrap();
      // console.log("Response From New Bag Inoculation",response);
      if (response.success) {
        setBagInoculationData({
          bagId: "",
          bagQty: bagInoculationData.bagQty,
          JamOrLcId: bagInoculationData.JamOrLcId,
          JamOrLcQty: bagInoculationData.JamOrLcQty
        });
      }

    } catch (error) {
      console.log(error);
    }
  };

  const clearAll = async () => {
    setBagInoculationData({
      JamOrLcId: "",
      JamOrLcQty: "",
      bagId: "",
      bagQty: ""
    });
  };

  //! we have use useCallback but still this component re-rending because of  handleScanner function {which is present in the QRScanner} .
  // const bagQtyResult = (result) => {
  //   setBagInoculationData({ ...bagInoculationData, bagQty: result });
  // };


  const bagIDResult = useCallback((result) => {
    setBagInoculationData(prevData => ({ ...prevData, bagId: result }));
  }, []);

  const bagQtyResult = useCallback((result) => {
    setBagInoculationData(prevData => ({ ...prevData, bagQty: result }));
  }, []);

  const JamOrLcIDResult = useCallback((result) => {
    setBagInoculationData(prevData => ({ ...prevData, JamOrLcId: result }));
  }, []);

  const JamOrLcQtyResult = useCallback((result) => {
    setBagInoculationData(prevData => ({ ...prevData, JamOrLcQty: result }));
  }, []);



  return (
    <>
      <form onSubmit={submitFilledBagInoculationData} className="max-w-sm bg-teal-50 p-4 sm:mx-auto mx-4 my-4 border border-teal-200 rounded-lg shadow-lg justify-center">

        <div className='p-1'>
          <label htmlFor="bagId" className="block mt-2 text-sm font-medium text-teal-900">
            {/* Scan Bag ID */}
            <QRScanner idName={"bagIdScanner"} buttonName={"Scan Bag ID"} resultOnScanSuccess={bagIDResult} />
          </label>
          <input
            type="text" id="bagId" value={bagInoculationData.bagId} name="bagId"
            onChange={(e) => handleInputDataOfBagInoculation(e.target.name, e.target.value)}
            className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Scan Bag ID"
            required
          />
        </div>

        <div className='p-1'>
          <label htmlFor="bagQty" className="block mt-2 text-sm font-medium text-teal-900">
            {/* Input Bag Quantity (in gm.) */}
            <QRScanner idName={"bagQtyScanner"} buttonName={"Scan Bag Qty."} resultOnScanSuccess={bagQtyResult} />
          </label>
          <input
            type="number" id="bagQty" value={bagInoculationData.bagQty} name="bagQty"
            onChange={(e) => handleInputDataOfBagInoculation(e.target.name, e.target.value)}
            className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Input Bag Quantity"
            required
            min={0}
          />
        </div>
        <div className='p-1'>
          <label htmlFor="JamOrLcId" className="block mt-2 text-sm font-medium text-teal-900">
            {/* Scan Jam/LC ID */}
            <QRScanner idName={"JamOrLcIdScanner"} buttonName={"Scan Jam/LC ID"} resultOnScanSuccess={JamOrLcIDResult} />
          </label>
          <input
            type="text" id="JamOrLcId" value={bagInoculationData.JamOrLcId} name="JamOrLcId"
            onChange={(e) => handleInputDataOfBagInoculation(e.target.name, e.target.value)}
            className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Input Jam/LC ID"
            required
          />
        </div>
        <div className='p-1'>

          <label htmlFor="JamOrLcQty" className="block mt-2 text-sm font-medium text-teal-900">
            {/* Fill Jam/LC Quantity (in ml.) */}
            <QRScanner idName={"JamOrLcQtyScanner"} buttonName={"Scan Jam/LC Qty."} resultOnScanSuccess={JamOrLcQtyResult} />
          </label>
          <input
            type="number" id="JamOrLcQty" value={bagInoculationData.JamOrLcQty} name="JamOrLcQty"
            onChange={(e) => handleInputDataOfBagInoculation(e.target.name, e.target.value)}
            className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Fill Jam/LC Quantity"
            required
            min={0}
          />

        </div>

        <div className='py-4'>
          <button
            type="submit"
            className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Submit
          </button>

          <button
            type="button"
            onClick={() => clearAll()}
            className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
          >
            Clear All
          </button>
        </div>
      </form>
    </>
  );
};

export default InoculateNewBag;




// import React, { useCallback, useState, useMemo } from 'react';
// import { useDispatch } from 'react-redux';
// import { fetchToInoculateNewBag } from '../../reduxStore/BagInoculation/BagInoculationSlice';
// import QRScanner from '../../assets/QRScanner';

// const InoculateNewBag = () => {
//   const dispatch = useDispatch();
//   const [bagInoculationData, setBagInoculationData] = useState({
//     JamOrLcId: "",
//     JamOrLcQty: "",
//     bagId: "",
//     bagQty: ""
//   });

//   const handleInputDataOfBagInoculation = (key, value) => {
//     setBagInoculationData(prevData => ({ ...prevData, [key]: value }));
//   };

//   const submitFilledBagInoculationData = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await dispatch(fetchToInoculateNewBag(bagInoculationData)).unwrap();
//       if (response.success) {
//         setBagInoculationData(prevData => ({
//           bagId: "",
//           bagQty: prevData.bagQty,
//           JamOrLcId: prevData.JamOrLcId,
//           JamOrLcQty: prevData.JamOrLcQty
//         }));
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const clearAll = () => {
//     setBagInoculationData({
//       JamOrLcId: "",
//       JamOrLcQty: "",
//       bagId: "",
//       bagQty: ""
//     });
//   };

//   // Memoized callback functions to prevent unnecessary re-renders
//   const bagIDResult = useCallback((result) => {
//     setBagInoculationData(prevData => ({ ...prevData, bagId: result }));
//   }, []);

//   const bagQtyResult = useCallback((result) => {
//     setBagInoculationData(prevData => ({ ...prevData, bagQty: result }));
//   }, []);

//   const JamOrLcIDResult = useCallback((result) => {
//     setBagInoculationData(prevData => ({ ...prevData, JamOrLcId: result }));
//   }, []);

//   const JamOrLcQtyResult = useCallback((result) => {
//     setBagInoculationData(prevData => ({ ...prevData, JamOrLcQty: result }));
//   }, []);

//   return (
//     <>
//       <form onSubmit={submitFilledBagInoculationData} className="max-w-sm bg-teal-50 p-4 sm:mx-auto mx-4 my-4 border border-teal-200 rounded-lg shadow-lg justify-center">
//         <div className='p-1'>
//           <label htmlFor="bagId" className="block mt-2 text-sm font-medium text-teal-900">
//             {/* Scan Bag ID */}
//             <QRScanner idName={"bagIdScanner"} buttonName={"Scan Bag ID"} resultOnScanSuccess={bagIDResult} />
//           </label>
//           <input
//             type="text" id="bagId" value={bagInoculationData.bagId} name="bagId"
//             onChange={(e) => handleInputDataOfBagInoculation(e.target.name, e.target.value)}
//             className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
//             placeholder="Scan Bag ID"
//             required
//           />
//         </div>

//         <div className='p-1'>
//           <label htmlFor="bagQty" className="block mt-2 text-sm font-medium text-teal-900">
//             {/* Input Bag Quantity (in gm.) */}
//             <QRScanner idName={"bagQtyScanner"} buttonName={"Scan Bag Qty."} resultOnScanSuccess={bagQtyResult} />
//           </label>
//           <input
//             type="number" id="bagQty" value={bagInoculationData.bagQty} name="bagQty"
//             onChange={(e) => handleInputDataOfBagInoculation(e.target.name, e.target.value)}
//             className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
//             placeholder="Input Bag Quantity"
//             required
//             min={0}
//           />
//         </div>

//         <div className='p-1'>
//           <label htmlFor="JamOrLcId" className="block mt-2 text-sm font-medium text-teal-900">
//             {/* Scan Jam/LC ID */}
//             <QRScanner idName={"JamOrLcIdScanner"} buttonName={"Scan Jam/LC ID"} resultOnScanSuccess={JamOrLcIDResult} />
//           </label>
//           <input
//             type="text" id="JamOrLcId" value={bagInoculationData.JamOrLcId} name="JamOrLcId"
//             onChange={(e) => handleInputDataOfBagInoculation(e.target.name, e.target.value)}
//             className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
//             placeholder="Input Jam/LC ID"
//             required
//           />
//         </div>

//         <div className='p-1'>
//           <label htmlFor="JamOrLcQty" className="block mt-2 text-sm font-medium text-teal-900">
//             {/* Fill Jam/LC Quantity (in ml.) */}
//             <QRScanner idName={"JamOrLcQtyScanner"} buttonName={"Scan Jam/LC Qty."} resultOnScanSuccess={JamOrLcQtyResult} />
//           </label>
//           <input
//             type="number" id="JamOrLcQty" value={bagInoculationData.JamOrLcQty} name="JamOrLcQty"
//             onChange={(e) => handleInputDataOfBagInoculation(e.target.name, e.target.value)}
//             className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
//             placeholder="Fill Jam/LC Quantity"
//             required
//             min={0}
//           />
//         </div>

//         <div className='py-4'>
//           <button
//             type="submit"
//             className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
//           >
//             Submit
//           </button>

//           <button
//             type="button"
//             onClick={clearAll}
//             className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
//           >
//             Clear All
//           </button>
//         </div>
//       </form>
//     </>
//   );
// };

// export default InoculateNewBag;

import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';

const Navbar = ({moduleName , home , viewData}) => {
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    return (
        <div className='sticky top-0 z-50 '>
        <nav className="bg-white border-teal-200 dark:bg-teal-900 dark:border-teal-700">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                <Link to={home} className="flex items-center space-x-3 rtl:space-x-reverse">          
                    {/* <img src="./assets/logo/logo.webp" alt="dharaksha logo"  className="h-12"/> */}
                    <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">{moduleName}</span>
                </Link>
                <button
                    onClick={() => setIsNavbarCollapsed(!isNavbarCollapsed)}
                    type="button"
                    className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-teal-500 rounded-lg md:hidden hover:bg-teal-100 focus:outline-none focus:ring-2 focus:ring-teal-200 dark:text-teal-400 dark:hover:bg-teal-700 dark:focus:ring-teal-600"
                    aria-controls="navbar-multi-level"
                    aria-expanded={isNavbarCollapsed}
                >
                    <span className="sr-only">Open main menu</span>
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                 
                </button>
                <div className={`${isNavbarCollapsed ? '' : 'hidden'} w-full md:block md:w-auto`} id="navbar-multi-level">
                    <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-teal-100 rounded-lg bg-teal-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-teal-800 md:dark:bg-teal-900 dark:border-teal-700">
                        <li>
                            <NavLink to={home} className="block py-2 px-3 text-teal-900 rounded hover:bg-teal-100 md:hover:bg-transparent md:border-0 md:hover:text-teal-700 md:p-0 dark:text-white md:dark:hover:text-teal-500 dark:hover:bg-teal-700 dark:hover:text-white md:dark:hover:bg-transparent" aria-current="page">Home</NavLink>
                        </li>
                        <li>
                            <NavLink to={viewData} className="block py-2 px-3 text-teal-900 rounded hover:bg-teal-100 md:hover:bg-transparent md:border-0 md:hover:text-teal-700 md:p-0 dark:text-white md:dark:hover:text-teal-500 dark:hover:bg-teal-700 dark:hover:text-white md:dark:hover:bg-transparent">{moduleName} Data</NavLink>
                        </li>
                        <li>
                            <NavLink to='/myProfile' className="block py-2 px-3 text-teal-900 rounded hover:bg-teal-100 md:hover:bg-transparent md:border-0 md:hover:text-teal-700 md:p-0 dark:text-white md:dark:hover:text-teal-500 dark:hover:bg-teal-700 dark:hover:text-white md:dark:hover:bg-transparent">Profile</NavLink>
                        </li>
                   
                        <li className="relative">
                            <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} id="dropdownDefaultButton" className="block py-2 px-3 text-white bg-white-700 rounded md:bg-transparent md:text-white-700 md:p-0 md:dark:text-white-500 dark:bg-white-600 md:dark:bg-transparent md:dark:hover:text-teal-500 md:hover:text-teal-700" type="button">
                                More
                                <svg className="w-2.5 h-2.5 ml-2 inline" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                </svg>
                            </button>
                            <div className={`${isDropdownOpen ? '' : 'hidden'} z-20 absolute bg-white divide-y divide-teal-100 rounded-lg shadow w-44 dark:bg-teal-700`}>
                                <ul className="py-2 text-sm text-teal-700 dark:text-teal-200" aria-labelledby="dropdownDefaultButton">
                                    
                                    <li>
                                        <NavLink to="/logout" className="block px-4 py-2 hover:bg-teal-100 dark:hover:bg-teal-600 dark:hover:text-white">Logout</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                      
                    </ul>
                </div>
            </div>
        </nav>
        </div>
    );
};

export default Navbar;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchToRejectNewDeMould } from '../../reduxStore/RejectedDeMoulding/RejectedDeMouldingSlice';
import QRScanner from '../../assets/QRScanner';

const RejectNewDeMoulding = () => {
    const dispatch = useDispatch();
    const [rejectedDeMouldingData, setRejectedDeMouldingData] = useState({
        rejectedDeMouldId: ""
    });

    const handleInputDataOfMouldFilling = (key, value) => {
        setRejectedDeMouldingData({ [key]: value });
    };

    const submitFilledRejectedDeMouldingData = async (deMouldId) => {
        const rejectedDeMouldingId = {
            rejectedDeMouldId: deMouldId
        }

        try {
            const response = await dispatch(fetchToRejectNewDeMould(rejectedDeMouldingId)).unwrap();
            if (response.success) {
                setRejectedDeMouldingData({
                    rejectedDeMouldId: ""
                });
            }        
        } catch (error) {
            console.log(error);
        }
    };


    const deMouldIDResult = (result) => {
        setRejectedDeMouldingData({ rejectedDeMouldId: result });
        submitFilledRejectedDeMouldingData(result)
      
    };



    const clearAll = async () => {
        setRejectedDeMouldingData({
            rejectedDeMouldId: ""
        });
    };

    return (
        <>
            <div  className="max-w-sm bg-teal-50 p-4 sm:mx-auto mx-4 my-4 border border-teal-200 rounded-lg shadow-lg justify-center">
                <label htmlFor="rejectedDeMouldId" className="block mt-2 text-sm font-medium text-teal-900">
                    Scan De-Mould ID
                    <QRScanner idName={"rejectedDeMouldIdScanner"} buttonName={"Scan De-Mould ID"} resultOnScanSuccess={deMouldIDResult} />
                </label>
                <input
                    type="text" id="rejectedDeMouldId" value={rejectedDeMouldingData.rejectedDeMouldId} name="rejectedDeMouldId"
                    onChange={(e) => handleInputDataOfMouldFilling(e.target.name, e.target.value)}
                    className="bg-teal-900 border border-teal-300 text-teal-900 text-sm rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5 dark:bg-teal-900 dark:border-teal-600 dark:placeholder-teal-400 dark:text-white dark:focus:ring-teal-500 dark:focus:border-teal-500"
                    placeholder="Input De-Mould ID"
                    required
                />
                <div className='py-4'>
                    <button
                        type="button"
                        onClick={() => submitFilledRejectedDeMouldingData(rejectedDeMouldingData.rejectedDeMouldId)}
                        className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    >
                        Submit
                    </button>

                    {/* <button
                        type="submit"
                        className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    >
                        Submit
                    </button> */}

                    <button
                        type="button"
                        
                        onClick={() => clearAll()}
                        className="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                    >
                        Clear
                    </button>
                </div>
            </div>
        </>
    );
};



export default RejectNewDeMoulding

import React, { useEffect, useState } from 'react'
import AdminDataOfMouldIncubation from './AdminDataOfMouldIncubation'
import { useSelector } from 'react-redux';
import { fetchToGetRejectedMoldData } from '../../../reduxStore/MouldIncubation/MouldIncubationSlice';
import FormatTime from '../../../assets/FormatTime';
import FilterData from '../../../assets/FilterData';
import ExportInExcel from '../../../assets/ExportInExcel';
import Pagination from '../../../assets/Pagination';


const AdminMouldIncubation = () => {
  const state = useSelector((state) => state.mouldIncubationReducer);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [totalNumberOfData, setTotalNumberOfData] = useState(0); // Ensure initialized to 0
  const [resultPerPage, setResultPerPage] = useState(0); // Ensure initialized to 0
  const extraFilters = {
    "mouldId": "Mould ID",
    "bagId": "Bag ID",
    "typeOfMould": "Type Of Mould",
    "filledBy": "Mould Filled By",
  };


  useEffect(() => {
    if (state?.data?.mouldRejectedData) {
      setAllData(state.data.mouldRejectedData);
      setTotalNumberOfData(state.data.totalRejectedMould || 0); // Fallback to 0 if undefined
      setResultPerPage(state.data.resultPerPage || 0); // Fallback to 0 if undefined
    }
  }, [state]);

  useEffect(() => {
    const transformData = allData.map(item => ({
      "Mould ID": item.mouldId,
      "Reason Of Rejection": item.reasonOfRejection,
      "Type of Mould": item.previousDetailsTillMouldFilling?.typeOfMould,
      "Mould Rejection Date": FormatTime(item.rejectionTime),
      "Mould Filling Date": FormatTime(item.previousDetailsTillMouldFilling?.fillingDate),
      "Mould Rejected By": item.mouldRejectedBy,
    }));

    setData(transformData);
  }, [allData]);


  return (
    <>




      <div className='flex justify-between px-3 py-4'>
        <FilterData extraFilters={extraFilters} getData={fetchToGetRejectedMoldData} />
        <ExportInExcel data={data} getData={fetchToGetRejectedMoldData} />
      </div>
      <AdminDataOfMouldIncubation />
      <Pagination getData={fetchToGetRejectedMoldData} totalData={totalNumberOfData} resultPerPage={resultPerPage} />



    </>
  )
}

export default AdminMouldIncubation

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../assets/Loader';
import { fetchToGetLcRejectionData} from '../../reduxStore/LcRejection/LcRejectionSlice';
import Pagination from '../../assets/Pagination';
import FormatTime from '../../assets/FormatTime'

const DataOfLcRejection = () => {
    const state = useSelector((state) => state.lcRejectionReducer); // Adjusted the selector name
    const dispatch = useDispatch();
    const [getAllDataFromCollection, setGetAllDataFromCollection] = useState([]);
   
    useEffect(() => {
        dispatch(fetchToGetLcRejectionData());
    }, [dispatch]);

    useEffect(() => {
        if (state && state.data && state.data.dataOfAllRejectedLc) {
            setGetAllDataFromCollection(state.data.dataOfAllRejectedLc);
        }
    }, [state]);


    const [totalNumberOfData, setTotalNumberOfData] = useState(0); // Ensure initialized to 0
    const [resultPerPage, setResultPerPage] = useState(0); // Ensure initialized to 0

    useEffect(() => {
        if (state && state.data && state.data.dataOfAllRejectedLc) {
          setTotalNumberOfData(state.data.totalLcRejectedData)
          setResultPerPage(state.data.resultPerPage)
        }
      }, [state]);


    return (
        <>
        <div className="flex flex-col">
            <div className="overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block">
                    <div className="border rounded-lg shadow overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC ID</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">Reason Of Rejection</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC Rejected</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC Quantity(in ml)</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">SC Id</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">SC Quantity(in ml.)</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC Preparation Date</th>
                                    <th className="px-6 py-6 text-sm font-medium text-gray-500 uppercase border">LC Rejection Date</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {state.isLoading &&
                                    <tr>
                                        <td colSpan={'8'} className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 text-center">
                                            <Loader />
                                        </td>
                                    </tr>
                                }

                                {getAllDataFromCollection.map((currData) => (
                                    <tr className='hover:bg-teal-300' key={currData._id}>
                                    
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.lcId}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.reasonOfRejection}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.lcRejectedBy}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.previousStatusTillLCPreparation.lcQty}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.previousStatusTillLCPreparation.scId}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{currData.previousStatusTillLCPreparation.scQty}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData.previousStatusTillLCPreparation.lcPreparationTime)}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 border">{FormatTime(currData.lcRejectionTime)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <Pagination getData={fetchToGetLcRejectionData} totalData={totalNumberOfData} resultPerPage={resultPerPage}/>
        </>
    );
};

export default DataOfLcRejection;

import React from 'react';

const Footer = () => {
    return (
      <div className='sticky bottom-2'>
      {/* <div className='relative'> */}
        <footer className="bg-white rounded-lg shadow m-4 dark:bg-teal-800 ">
            <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                <span className="text-sm text-white sm:text-center dark:text-white">
                    © 2024 <a href="https://www.dharaksha.com/" target='_blank' className="hover:underline">Dharaksha</a>. All Rights Reserved.
                </span>

                <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-white dark:text-white sm:mt-0">
                <span className="text-sm text-white sm:text-center dark:text-white">
                Developed & Maintained By <a href="https://github.com/ankit76350" target='_blank' className="underline">Ankit Kumar</a>.  
                </span>
                    
                    {/* <li>
                        <a href="#" className="hover:underline me-4 md:me-6">About</a>
                    </li>
                    <li>
                        <a href="#" className="hover:underline me-4 md:me-6">Privacy Policy</a>
                    </li>
                    <li>
                        <a href="#" className="hover:underline me-4 md:me-6">Licensing</a>
                    </li>
                    <li>
                        <a href="#" className="hover:underline">Contact</a>
                    </li> */}
                </ul>
            </div>
        </footer>
        </div>
    );
};

export default Footer;




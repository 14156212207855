import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const fetchAddedMouldsData = createAsyncThunk('growthChamber/getAddedMouldsData', async ({limit,page}) => {
    console.log("Limit :",limit)
    console.log("Page :",page)
    try {
        // let limit = limit || 50;
        // let page = page || 1;
        // const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST_URL;
        const BACK_HOST = process.env.REACT_APP_BACKEND_PORT_AND_HOST;
        // const url = `${BACK_HOST}humidifier/allMoulds?limit=${limit}&page=${page}`;
        const url = `${BACK_HOST}api/v1/humidifier/allMoulds?limit=${limit}&page=${page}`;
       
       console.log("url",url);
        const response = await fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            }
        })
        // console.log("response: ",response)
        if (!response.ok) {
            const errorData = await response.json();
            toast.error(errorData.message);
            return errorData
        }

        const data = await response.json();
        // toast.success(data.message)
        return data

    } catch (error) {
        console.error("Unable to fetch added moulds data ", error);
        throw error;
    }
})



const humidifierDataSlice = createSlice({
    name: "humidifierData",
    initialState: {
        data: null,
        isLoading: false,
        isError: false
    },
    reducers: {},
    extraReducers: (builder) => {

        // Fetch all humidifierDatas data
        builder.addCase(fetchAddedMouldsData.pending,(state)=>{
            state.isLoading = true;
        }).addCase(fetchAddedMouldsData.fulfilled,(state,action)=>{
            state.isLoading = false;
            // console.log("inredu: ",state,action)
            state.data = action.payload;
        }).addCase(fetchAddedMouldsData.rejected,(state,action)=>{
            console.log("Error", action.payload);
            state.isError = false;
            state.isLoading = false;
        })
    }
})

export default humidifierDataSlice.reducer
import React from 'react';

const Modal = ({ isOpen, closeModal, submitCreatedData }) => {
  return (
    <>
      {isOpen && submitCreatedData && (
        <div
          id="static-modal"
          className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-50"
          role="dialog"
          aria-modal="true"
        >
          <div className="relative p-4 w-full max-w-2xl">
            <div className="relative bg-white rounded-lg shadow-md">
              <div className="flex items-center justify-between p-4 md:p-5 border-b border-teal-500 rounded-t">
                <h3 className="text-xl font-semibold text-gray-900">
                  Static modal
                </h3>
              </div>

              <div className="p-4 md:p-5 space-y-4">
                  <span>Added Data:</span>
                <p className="text-base leading-relaxed text-gray-700 flex ">
                  {submitCreatedData?.map((currData, index) => (
                    <ul key={index} className='p-0.5'>{`${currData}`} </ul>
                  ))}
                </p>
              </div>

              <div className="flex bg-color-red items-center p-4 md:p-5 border-t border-teal-200 rounded-b">
                <button
                  onClick={closeModal}
                  type="button"
                  className="text-white bg-teal-500 hover:bg-teal-600 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;

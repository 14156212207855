import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminModuleNavigation from "./AdminModuleNavigation"
import AdminNavbar from "./AdminNavbar"
import ProfileCard from '../../assets/Profile'
import AdminJamPreparation from "./JamPreparation/AdminJamPreparation";
import AdminLcPreparation from "./LcPreparation/AdminLcPreparation";
import AdminLcRejection from "./LcRejection/AdminLcRejection";
import AdminBagInoculation from "./BagInoculation/AdminBagInoculation";
import AdminBagStoreRoom from "./BagStoreRoom/AdminBagStoreRoom";
import AdminMouldFilling from "./MouldFilling/AdminMouldFilling";
import AdminMouldIncubation from "./MouldIncubation/AdminMouldIncubation";
import AdminOvenIn from "./OvenIn/AdminOvenIn";
import AdminOvenOut from "./OvenOut/AdminOvenOut";
import Login from "../../assets/Login";
import AdminDataOfUsers from "./User/AdminDataOfUsers";
import Logout from "../../assets/Logout";
import AdminTypeOfMould from "./MouldFilling/AdminTypeOfMould";
import AdminDeMouldingRejection from "./RejectedDeMoulding/AdminDeMouldingRejection";
import Footer from "../Footer";
import Dashboard from "./Dashboard/Dashboard";
import AdminHumidifier from "./GrowthChamber/AdminHumidifier";
import AdminHumidifierStatus from "./GrowthChamber/AdminHumidifierStatus";
import AdminHumidifiersData from "./GrowthChamber/AdminHumidifiersData";
import AdminHumidifierGrowthData from "./GrowthChamber/AdminHumidifierGrowthData";
import AdminOven from "./OvenIn/AdminOven";



function Admin() {
  return (
    <>
      <BrowserRouter>
        <AdminNavbar />

        <Routes>
          <Route path="/" element={<AdminModuleNavigation />} />


          <Route path="/admin/dashboard" element={< Dashboard />} />
          <Route path="/admin/jamPreparation" element={< AdminJamPreparation />} />
          <Route path="/admin/lcPreparation" element={< AdminLcPreparation />} />
          <Route path="/admin/lcRejection" element={< AdminLcRejection />} />
          <Route path="/admin/bagInoculation" element={<AdminBagInoculation />} />
          <Route path="/admin/bagStoreRoom" element={<AdminBagStoreRoom />} />

          <Route path="/admin">
            <Route path="mouldFilling" element={<AdminMouldFilling />} />
            <Route path="typeOfMould" element={<AdminTypeOfMould />} />
          </Route>

          <Route path="/admin/mouldIncubation" element={<AdminMouldIncubation />} />

          <Route path="/admin">
            <Route path="humidifier" element={<AdminHumidifier />} />
            <Route path="humidifierStatus" element={<AdminHumidifierStatus />} />
            <Route path="humidifiersData" element={<AdminHumidifiersData />} />
            {/* <Route path="humidifierGrowthData" element={<AdminHumidifiersData />} /> */}
          </Route>
          <Route path="/growthData" element={<AdminHumidifierGrowthData />} />


          <Route path="/admin/deMouldingRejection" element={<AdminDeMouldingRejection />} />

          {/* //! Oven  In */}
          <Route path="/admin">
            <Route path="oven" element={< AdminOven/>} />
            {/* <Route path="ovenStatus" element={<AdminHumidifierStatus />} /> */}
            <Route path="ovensData" element={<AdminOvenIn />} />
          </Route>



          <Route path="/admin/ovenOut" element={<AdminOvenOut />} />
          <Route path="/admin/user" element={<AdminDataOfUsers />} />
          <Route path="/admin/myProfile" element={<ProfileCard />} />
          <Route path="/admin/logout" element={<Logout />} />
          <Route path="/admin/login" element={<Login />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default Admin;

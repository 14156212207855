import React, { useEffect, useState } from 'react'
import AdminDataOfOvenOut from './AdminDataOfOvenOut'
import FormatTime from '../../../assets/FormatTime';
import FilterData from '../../../assets/FilterData';
import ExportInExcel from '../../../assets/ExportInExcel';
import Pagination from '../../../assets/Pagination';
import {  useSelector } from 'react-redux';
import { fetchAllOvenOutData } from '../../../reduxStore/OvenOut/OvenOutSlice';

const AdminOvenOut = () => {
  const state = useSelector((state) => state.ovenOutReducer);
  console.log("state",state);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [totalNumberOfData, setTotalNumberOfData] = useState(0); // Ensure initialized to 0
  const [resultPerPage, setResultPerPage] = useState(0); // Ensure initialized to 0
  const extraFilters = {
    "ovenOutFGIds": "FG ID",
    "batchIdOfOvenOut": "Oven Out Batch Id",
    "ovenOutTypeOfFG": "Type Of FG",
    "ovenOutBy": "Oven Out By",
  };


  useEffect(() => {
    if (state?.data?.ovenOutData) {
      setAllData(state.data.ovenOutData);
      setTotalNumberOfData(state.data.totalOvenOutFG || 0); // Fallback to 0 if undefined
      setResultPerPage(state.data.resultPerPage || 0); // Fallback to 0 if undefined
    }
  }, [state]);

  useEffect(() => {
    const transformData = allData.map(item => ({
      "OVEN OUT FG IDS": item.ovenOutFGIds,
      "QC CATEGORIES": item.ovenOutTypeOfFG,
      "OVEN OUT BATCH ID": item.batchIdOfOvenOut,
      "OVEN OUT TIME": FormatTime(item.ovenOutTime),
      "OVEN OUT BY": item.ovenOutBy,
      "OVEN IN BATCH ID": item.previousDetailsTillOvenIn?.batchIdOfOvenIn,
      "OVEN IN TIME": FormatTime(item.previousDetailsTillOvenIn.jamPreparationTime),
    }));

    setData(transformData);
  }, [allData]);

  return (
    <div>
      <div className='flex justify-between px-3 py-4'>
        <FilterData extraFilters={extraFilters} getData={fetchAllOvenOutData} />
        <ExportInExcel data={data} getData={fetchAllOvenOutData} />
      </div>
      <AdminDataOfOvenOut/>
      <Pagination getData={fetchAllOvenOutData} totalData={totalNumberOfData} resultPerPage={resultPerPage} />


    </div>
  )
}

export default AdminOvenOut

import React, { useState } from 'react';
import QRScanner from '../../assets/QRScanner';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ScanMultiIdsOvenOut = ({ scannerIdName, labelName, submitOvenOutData, checkIsIdExist }) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [errors, setErrors] = useState({});
  const [findIfExistsInTagList, setFindIfExistsInTagList] = useState(false);
  const [selectedFG, setSelectedFG] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setFindIfExistsInTagList(false); // Reset check flag on input change
  };

  const handleFGChange = (event) => {
    setSelectedFG(event.target.value);
  };

  const validationSchema = yup.object().shape({
    ovenOutFGIds: yup.string()
      .required('Please fill in an ID.')
      .min(3, 'ID should be at least 3 characters long.')
      .max(10, 'ID should not exceed 10 characters.')
      .test('is-unique', 'ID already exists in the list.', function (value) {
        return !tags.includes(value);
      }),
  });

  const resultOnScan = (result) => {
    setInputValue(result);
    addNewId(result);
  };

  const addNewId = async (newId) => {
    try {
      let formData = { ovenOutFGIds: newId.trim() };
      await validationSchema.validate(formData, { abortEarly: false });
      const backendCheck = await checkIsIdExist(newId.trim());
      if (!backendCheck.success) {
        setFindIfExistsInTagList(true);
        return;
      }
      setTags([...tags, newId.trim()]);
      setInputValue('');
      setErrors({});
      setFindIfExistsInTagList(false);
    } catch (error) {
      const newErrors = {};
      error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setErrors(newErrors);
      setFindIfExistsInTagList(true);
    }
  };

  const handleRemoveTag = (index) => {
    setTags(tags.filter((_, i) => i !== index));
  };

  const submitAllInputIds = () => {
    if (tags.length === 0) {
      toast.error('Please input IDs.');
    } else if (!selectedFG) {
      toast.error('Please select a type of FG.');
    } else {
      const result = {
        ovenOutFGIds: tags,
        ovenOutTypeOfFG: selectedFG
      };
      submitOvenOutData(result);
      setTags([]);
    }
  };

  return (
    <div className="max-w-sm mx-auto max-sm:mx-4 shadow-xl rounded p-4 my-12">
      <label htmlFor={scannerIdName} className="block mt-2 text-sm font-medium text-teal-900">
        {`Scan ${labelName}`}
        <QRScanner idName={scannerIdName} buttonName="Scan IDs" resultOnScanSuccess={resultOnScan} />
      </label>
      <div className="grid grid-cols-3">
        <div className="max-w-sm col-span-2">
          <input
            id={scannerIdName}
            name="ovenOutFGIds"
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            className={`${findIfExistsInTagList ? 'bg-red-900 border border-red-300' : 'bg-teal-900 border border-teal-300'} text-white text-sm rounded-lg block w-full p-2.5`}
            placeholder="Enter an ID (M0012)"
          />
        </div>
        <div className="col-span-1 ml-2">
          <button
            type="button"
            onClick={() => addNewId(inputValue)}
            className="text-white bg-teal-700 hover:bg-teal-800 focus:ring-4 focus:ring-teal-300 font-medium rounded-lg text-sm px-5 py-2.5"
          >
            Add ID
          </button>
        </div>

        {errors.ovenOutFGIds && <p className="col-span-3 text-red-500 text-sm font-medium">{errors.ovenOutFGIds}</p>}
        <div className="col-span-3">
          <div className="block mt-2 text-sm font-medium text-teal-900">FG IDs:</div>
          <div className="shadow w-full font-medium rounded-lg text-sm px-5 py-2.5 mb-2">
            {tags.length === 0 && <div className="text-red-500">No IDs Present</div>}
            <div className="flex flex-wrap gap-2 mt-3">
              {tags.map((tag, index) => (
                <div
                  key={index}
                  className={`${tag === inputValue ? ' bg-red-200 hover:bg-red-100' : 'bg-teal-200 hover:bg-teal-100'} overflow-hidden text-black px-3 py-1 rounded-full text-sm flex items-center `}
                >
                  {tag}
                  <button
                    className="ml-2 text-red-500 hover:bg-red-500 rounded-full"
                    onClick={() => handleRemoveTag(index)}
                  >
                    <img src="./assets/logo/remove.png" className="h-5 w-5" alt="remove icon" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col col-span-3 mr-2">
          <label htmlFor="ovenOutTypeOfFG" className="block mt-2 text-sm font-medium text-teal-900">
            Select Type of FG:
            <div className='flex items-center mt-2'>
              <input
                id="ovenOutTypeOfFG-1"
                type="radio"
                value="T1"
                name="ovenOutTypeOfFG"
                className="w-4 h-4 text-teal-600 bg-teal-100 border-teal-300 focus:ring-teal-500 dark:focus:ring-teal-600 dark:ring-offset-teal-800 focus:ring-2 dark:bg-teal-700 dark:border-teal-600"
                checked={selectedFG === 'T1'}
                onChange={handleFGChange}
              />
              <label htmlFor="ovenOutTypeOfFG-1" className="ms-2 text-sm font-medium text-teal-900 dark:text-teal-900">T1</label>
            </div>

            <div className='flex items-center mt-2'>
              <input
                id="ovenOutTypeOfFG-2"
                type="radio"
                value="T2"
                name="ovenOutTypeOfFG"
                className="w-4 h-4 text-teal-600 bg-teal-100 border-teal-300 focus:ring-teal-500 dark:focus:ring-teal-600 dark:ring-offset-teal-800 focus:ring-2 dark:bg-teal-700 dark:border-teal-600"
                checked={selectedFG === 'T2'}
                onChange={handleFGChange}
              />
              <label htmlFor="ovenOutTypeOfFG-2" className="ms-2 text-sm font-medium text-teal-900 dark:text-teal-900">T2</label>
            </div>

            <div className='flex items-center mt-2'>
              <input
                id="ovenOutTypeOfFG-3"
                type="radio"
                value="T3"
                name="ovenOutTypeOfFG"
                className="w-4 h-4 text-teal-600 bg-teal-100 border-teal-300 focus:ring-teal-500 dark:focus:ring-teal-600 dark:ring-offset-teal-800 focus:ring-2 dark:bg-teal-700 dark:border-teal-600"
                checked={selectedFG === 'T3'}
                onChange={handleFGChange}
              />
              <label htmlFor="ovenOutTypeOfFG-3" className="ms-2 text-sm font-medium text-teal-900 dark:text-teal-900">T3</label>
            </div>
          </label>

          <button
            type="button"
            onClick={submitAllInputIds}
            className="text-teal-700 hover:text-white border border-teal-700 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-6"
          >
            Submit
          </button>

        </div>
      </div>
    </div>
  );
};

export default ScanMultiIdsOvenOut;
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AdminDataOfLcRejection from './AdminDataOfLcRejection';
import RejectNewLc from '../../LcRejection/RejectNewLc';
import FilterData from '../../../assets/FilterData';
import ExportInExcel from '../../../assets/ExportInExcel';
import Pagination from '../../../assets/Pagination';
import { fetchToGetLcRejectionData } from '../../../reduxStore/LcRejection/LcRejectionSlice';
import FormatTime from '../../../assets/FormatTime';
import DataOfLcRejection from '../../LcRejection/DataOfLcRejection';

const AdminLcRejection = () => {
  const state = useSelector((state) => state.lcRejectionReducer);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [totalNumberOfData, setTotalNumberOfData] = useState(0); // Ensure initialized to 0
  const [resultPerPage, setResultPerPage] = useState(0); // Ensure initialized to 0

  const extraFilters = {
    "lcId": "LC ID",
    "reasonOfRejection": "Reason Of Rejection",
    "lcRejectedBy": "Input Person Name"
  };

  useEffect(() => {
    if (state?.data?.dataOfAllRejectedLc) {
      setAllData(state.data.dataOfAllRejectedLc);
      setTotalNumberOfData(state.data.totalLcRejectedData || 0); // Fallback to 0 if undefined
      setResultPerPage(state.data.resultPerPage || 0); // Fallback to 0 if undefined
    }
  }, [state]);

  useEffect(() => {
    const transformData = allData.map(item => ({
      "LC ID": item.lcId,
      "Reason Of Rejection": item.reasonOfRejection,
      "LC Rejected By": item.lcRejectedBy,
      "Lc Quantity": item.previousStatusTillLCPreparation.lcQty,
      "SC ID": item.previousStatusTillLCPreparation.scId,
      "SC Quantity": item.previousStatusTillLCPreparation.scQty,
      "LC Preparation Time": FormatTime(item.previousStatusTillLCPreparation.lcPreparationTime),
      "LC Rejection Time": FormatTime(item.lcRejectionTime),
    }));

    setData(transformData);
  }, [allData]);

  return (
    <>
  
      <div className='flex justify-between px-3 py-4'>
        <FilterData extraFilters={extraFilters} getData={fetchToGetLcRejectionData} />
        <ExportInExcel data={data} getData={fetchToGetLcRejectionData} />
      </div>
      <AdminDataOfLcRejection />
      <Pagination getData={fetchToGetLcRejectionData} totalData={totalNumberOfData} resultPerPage={resultPerPage} />

    </>
  );
};

export default AdminLcRejection;

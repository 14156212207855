import React from 'react'
import AllModuleDataChart from './AllModuleDataChart'
import LcModulesChart from './LcModulesChart'
import BagModulesChart from './BagModulesChart'
import MouldsModulesCharts from './MouldsModulesCharts'

const Dashboard = () => {
    return (
        <>
            <div className='container px-5 py-5 mx-auto'>
                <AllModuleDataChart />
                <LcModulesChart/>
                <BagModulesChart/>
                <MouldsModulesCharts/>
            </div>

        </>
    )
}

export default Dashboard

import React, { useState } from 'react';
import QRScanner from '../../assets/QRScanner'; // Make sure to replace with actual QRScanner component
import * as yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MultipleFgIdsInput = ({ scannerIdName, labelName, onSubmitGetAllInputIds, checkIsIdExist, oven }) => {
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [errors, setErrors] = useState({});
    const [findIfExistsInTagList, setFindIfExistsInTagList] = useState(false);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        setFindIfExistsInTagList(false); // Reset error state on input change
    };

    const validationSchema = yup.object().shape({
        semiFGId: yup.string()
            .required('Please fill in an ID.')
            .min(3, 'ID should be at least 3 characters long.')
            .max(10, 'ID should not exceed 10 characters.')
            .test('is-unique', 'ID already exists in the list.', function (value) {
                return !tags.includes(value);
            })
    });

    const addNewId = async (id) => {
        try {
            let formData = { semiFGId: id.trim() };
            await validationSchema.validate(formData, { abortEarly: false });

            // Check if ID exists in the backend
            const backendCheck = await checkIsIdExist(id.trim());
            if (!backendCheck.success) {
                setFindIfExistsInTagList(true);
                return;
            }
            setTags([...tags, id.trim()]);
            setInputValue('');
            setErrors({});
            setFindIfExistsInTagList(false);
        } catch (error) {
            const newErrors = {};
            error.inner.forEach((err) => {
                newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
            setFindIfExistsInTagList(true); // Set error state for existing ID
        }
    };

    const handleRemoveTag = (index) => {
        setTags(tags.filter((_, i) => i !== index));
    };

    const resultOnScan = (result) => {
        setInputValue(result);
        addNewId(result);
    };

    const submitAllInputIds = () => {
        if (tags.length === 0) {
            toast.error('Please input IDs.');
        } else {
            onSubmitGetAllInputIds(tags);
            setTags([])
        }
    };

    console.log("oven", oven);




    return (
        <>
            <div className="max-w-sm mx-auto max-sm:mx-4 shadow-xl rounded p-4 my-12">
                <div className='font-bold text-2xl mx-auto w-full'>Oven {oven.serialNo}</div>
                <label htmlFor={scannerIdName} className="block mt-2 text-sm font-medium text-teal-900">
                    {/* {`Scan ${labelName}`} */}
                    <QRScanner idName={scannerIdName} buttonName="Scan IDs" resultOnScanSuccess={resultOnScan} />
                </label>

                <div className="grid grid-cols-3 gap-4">
                    <div className="max-w-sm col-span-2">
                        <input
                            id={scannerIdName}
                            name="semiFGId"
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                            className={`${findIfExistsInTagList ? 'bg-red-900 border border-red-300' : 'bg-teal-900 border border-teal-300'
                                } text-white text-sm rounded-lg block w-full p-2.5`}
                            placeholder="Enter an ID (M0012)"
                        />
                        {errors.semiFGId && <p className="text-red-500">{errors.semiFGId}</p>}


                    </div>

                    <div className="col-span-1 ml-2">

                        <button
                            type="button"
                            onClick={() => addNewId(inputValue)}
                            className="text-white bg-teal-700 hover:bg-teal-800 focus:ring-4 focus:ring-teal-300 font-medium rounded-lg text-sm px-5 py-2.5"
                        >
                            Add ID
                        </button>
                    </div>

                    <div className="col-span-3">
                        <label htmlFor="generateNewOvenInBatchId">Select Batch IDs</label>
                        <select
                            name="generateNewOvenInBatchId"
                            id="generateNewOvenInBatchId"
                            className="bg-teal-900 border border-teal-300 text-white text-sm rounded-lg block w-full p-2.5"
                        >
                            <option value="generateNewOvenInBatchId">Generate New Batch Id</option>
                            <option value="id2">Batch ID 2</option>
                            <option value="id3">Batch ID 3</option>
                        </select>

                    </div>



                    <div className="col-span-3">
                        <div className="block mt-2 text-sm font-medium text-teal-900">Semi FG IDs:</div>
                        <div className="shadow w-full font-medium rounded-lg text-sm px-5 py-2.5 mb-2">
                            {tags.length === 0 && <div className="text-red-500">No IDs Present</div>}
                            <div className="flex flex-wrap gap-2 mt-3">
                                {tags.map((tag, index) => (
                                    <div
                                        key={index}
                                        className={`${tag === inputValue ? ' bg-red-200 hover:bg-red-100' : 'bg-teal-200 hover:bg-teal-100'
                                            } overflow-hidden text-black px-3 py-1 rounded-full text-sm flex items-center `}
                                    >
                                        {tag}
                                        <button
                                            className="ml-2 text-red-500 hover:bg-red-500 rounded-full"
                                            onClick={() => handleRemoveTag(index)}
                                        >
                                            <img src="./assets/logo/remove.png" className="h-5 w-5" alt="remove icon" />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        onClick={submitAllInputIds}
                        className="col-span-3 text-teal-700 hover:text-white border border-teal-700 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-2 mb-2"
                    >
                        Submit
                    </button>
                </div>

            </div>

        </>
    );
};

export default MultipleFgIdsInput;
